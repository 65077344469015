import { Box, Divider, Stack, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { DirectionsRun } from '@material-ui/icons';
import type { FC } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES } from 'src/constants';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';
import CustomAvatar from '../CustomComponents/CustomAvatar';
import CustomButton from '../CustomComponents/CustomButton';
import CustomPopover from '../CustomComponents/CustomPopover';
import GetFlag from '../shared/GetFlag';
import WarningPopup from '../shared/WarningPopup';
import { externalLinks } from 'src/config';
import Cookies from 'js-cookie';

const AccountPopover: FC = () => {
  const [warning, setWarning] = useState(false);
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const allowedRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.MANAGER];

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <>
      <CustomPopover
        modalOnMobile
        align="center"
        popoverProps={{
          PaperProps: {
            sx: {
              minWidth: '150px',
              padding: '10px',
            },
          },
        }}
        triggerEl={
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
            <CustomButton variant="text" size="auto" sx={{ position: 'relative', height: '32px', width: '32px' }}>
              <CustomAvatar src={user?.avatar} size={26} seed={user?.name + ' ' + user?.surname} />

              <GetFlag
                country={user.nation}
                style={{
                  width: '15px',
                  height: '12px',
                  position: 'absolute',
                  left: '50%',
                  bottom: 0,
                  borderRadius: '3px',
                  transform: 'translate(-50%, 25%)',
                  border: '1px solid white',
                  display: 'flex',
                }}
              />
            </CustomButton>
          </Box>
        }
      >
        <CustomButton
          variant="transparent"
          size="auto"
          onClick={() => window.open(`${process.env.REACT_APP_CLIENT_URL}/athlete/${user?.id}/${user?.name}-${user?.surname}?tab=overview`)}
          sx={{ mb: 2, display: 'flex', justifyContent: 'left', gap: '4px' }}
        >
          <CustomButton variant="text" size="auto" sx={{ position: 'relative', height: '32px', width: '32px' }}>
            <CustomAvatar src={user?.avatar} size={26} seed={user?.name + ' ' + user?.surname} />

            <GetFlag
              country={user.nation}
              style={{
                width: '15px',
                height: '12px',
                position: 'absolute',
                left: '50%',
                bottom: 0,
                borderRadius: '3px',
                transform: 'translate(-50%, 25%)',
                border: '1px solid white',
                display: 'flex',
              }}
            />
          </CustomButton>
          <Stack justifyContent="center" gap="4px">
            <CustomButton variant="text" size="auto" sx={{ padding: 0, height: 'auto', width: 'fit-content' }}>
              <Typography color="#0F2927" variant="font15" sx={{ lineHeight: '100%' }}>
                {user.name} {user.surname}
              </Typography>
            </CustomButton>
            <CustomButton variant="text" size="auto" sx={{ padding: 0, height: 'auto', width: 'fit-content' }}>
              <Typography color="#86909F" variant="font21" sx={{ lineHeight: '100%', textTransform: 'capitalize' }}>
                {user.role}
              </Typography>
            </CustomButton>
          </Stack>
        </CustomButton>

        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', gap: '5px' }}>
          {allowedRoles.includes(user?.role) && !mdUp && (
            <CustomButton
              variant="transparent"
              size="small"
              sx={{ gap: '4px', justifyContent: 'left', alignItems: 'center' }}
              onClick={() => {
                window.open(process.env.REACT_APP_CLIENT_URL);
              }}
            >
              <DirectionsRun sx={{ fontSize: '16px' }} />
              {t('Athlete Portal')}
            </CustomButton>
          )}
          <CustomButton
            variant="transparent"
            size="small"
            sx={{ gap: '4px', justifyContent: 'left', alignItems: 'center' }}
            onClick={() => window.open(`${externalLinks.userProfile + user.id}?token=${Cookies.get('token')}`)}
          >
            <UserIcon sx={{ fontSize: '16px' }} />
            {t('Profile')}
          </CustomButton>
          <CustomButton
            variant="transparent"
            size="small"
            sx={{ gap: '4px', justifyContent: 'left', alignItems: 'center' }}
            onClick={() => window.open(`${externalLinks.userSettings}?token=${Cookies.get('token')}`)}
          >
            <CogIcon sx={{ fontSize: '16px' }} />

            {t('Settings')}
          </CustomButton>
          <CustomButton onClick={() => setWarning(true)} variant="outline" size="small">
            {t('Logout')}
          </CustomButton>
        </Box>
      </CustomPopover>
      <WarningPopup
        openWarningModal={warning}
        handleClose={() => setWarning(false)}
        handleAction={handleLogout}
        warningTitle="Confirm logout"
        warningText="Are you sure you want to logout?"
        confirmText="Logout"
      />
    </>
  );
};

export default AccountPopover;
