import { gql } from '@apollo/client';

const UPDATE_COURTS_SORTING = gql`
  mutation updateCourtSorting($courts: [JSONObject!]!) {
    updateCourtSorting(updateCourtInput: { courts: $courts }) {
      id
    }
  }
`;

export default UPDATE_COURTS_SORTING;
