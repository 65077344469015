import { gql } from '@apollo/client';

const UPDATE_GROUP_ENTRIES = gql`
  mutation updateGroupEntry($id: Int!, $registrationRequest: Int, $group: Int, $place: Int) {
    updateGroupEntry(updateGroupEntryInput: { id: $id, registrationRequest: $registrationRequest, group: $group, place: $place }) {
      id
    }
  }
`;

export default UPDATE_GROUP_ENTRIES;
