import { gql } from '@apollo/client';

const ADDITIONAL_QUESTION_BY_ID = gql`
  query additionalQuestion($id: Int!) {
    additionalQuestion(id: $id) {
      id
      question
      fieldType
      fieldOptions
      askEachAthlete
      askOnlyOnce
      isMandatory
      isHide
      tournamentCategories {
        id
        category {
          id
          name
        }
      }
    }
  }
`;

export default ADDITIONAL_QUESTION_BY_ID;
