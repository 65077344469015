import { gql } from '@apollo/client';

const CREATE_MATCHES = gql`
  mutation createGroupMatch($tournament: Int!, $group: Int!) {
    createGroupMatch(createGroupMatchInput: { tournament: $tournament, group: $group }) {
      id
    }
  }
`;

export default CREATE_MATCHES;
