import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT_REGISTERATION = gql`
  mutation updateTournamentRegistration(
    $id: Int!
    $request: String
    $status: String
    $assignedSegment: String
    $additionalSegment: String
    $paymentMethod: String
    $amount: Float
    $seed: Int
    $paymentStatus: PaymentStatus
    $categoryId: Int
  ) {
    updateTournamentRegistration(
      updateTournamentRegistrationInput: {
        id: $id
        request: $request
        status: $status
        assignedSegment: $assignedSegment
        additionalSegment: $additionalSegment
        paymentMethod: $paymentMethod
        amount: $amount
        seed: $seed
        categoryId: $categoryId
        paymentStatus: $paymentStatus
      }
    ) {
      id
    }
  }
`;

export default UPDATE_TOURNAMENT_REGISTERATION;
