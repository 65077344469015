import { gql } from "@apollo/client";

const UPDATE_GROUP = gql`
  mutation updateGroup(
    $id: Int!
    $hide: Boolean
    $name: String
    $displayMatches: Boolean
    $displayPlayOrder: Boolean
    $comment: String
    $groupType: String
  ) {
    updateGroup(
      updateGroupInput: {
        id: $id
        hide: $hide
        name: $name
        displayPlayOrder: $displayPlayOrder
        displayMatches: $displayMatches
        comment: $comment
        groupType: $groupType
      }
    ) {
      id
      name
      groupType
      displayPlayOrder
      displayMatches
      hide
      comment
    }
  }
`;

export default UPDATE_GROUP;