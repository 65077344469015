import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

interface drawsSettingsState {
    selectedCategory: number;
    selectedSegment: string;
    selectedDraw: number;
    hideAllDraws: boolean;
    groupsPagination: { page: number; limit: number; total: number; loading: boolean; }
    noOfDraws?: number;
}

const initialState: drawsSettingsState = {
    selectedCategory: null,
    selectedSegment: null,
    selectedDraw: null,
    hideAllDraws: Cookies.get('hideAllDraws') === 'true',
    groupsPagination: { page: 1, limit: 1, total: null, loading: false },
    noOfDraws: 0
};

const drawsSettings = createSlice({
    name: 'drawsSettings',
    initialState,
    reducers: {
        setSelectedCategory: (state, action: PayloadAction<number>) => {
            state.selectedCategory = action.payload;
        },
        setSelectedSegment: (state, action: PayloadAction<string>) => {
            state.selectedSegment = action.payload;
        },
        setSelectedDraw: (state, action: PayloadAction<number>) => {
            state.selectedDraw = action.payload;
        },
        setHideAllDraws: (state, action: PayloadAction<boolean>) => {
            state.hideAllDraws = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.groupsPagination.loading = action.payload;
        },
        setTotal: (state, action: PayloadAction<number>) => {
            state.groupsPagination.total = action.payload;
        },
        loadMore: (state) => {
            state.groupsPagination.page ++;
        },
        reset: (state) => {
            state.groupsPagination.page = 1;
            state.groupsPagination.total = null;
        },
        setNoOfDraws: (state, action: PayloadAction<number>) => {
            state.noOfDraws = action.payload;
        }
    },
});

export const { setSelectedCategory, setSelectedSegment, setSelectedDraw, setHideAllDraws, setLoading, setTotal, loadMore, reset, setNoOfDraws } = drawsSettings.actions;

export default drawsSettings.reducer;
