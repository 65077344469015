import { gql } from "@apollo/client";

const ADD_TEAM_TO_GROUP = gql`
  mutation createGroupEntry(
    $group: Int!
    $registrationRequests: [Int!]!
    $tournamentCategory: Int!
    $tournament: Int!
  ) {
    createGroupEntry(
      createGroupEntryInput: {
        group: $group
        registrationRequests: $registrationRequests
        tournamentCategory: $tournamentCategory
        tournament: $tournament
      }
    ) {
      id
    }
  }
`;

export default ADD_TEAM_TO_GROUP;
