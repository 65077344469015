import { gql } from '@apollo/client';
const RANKING_POINTS = gql`
  query rankingPoints($filter: ListRankingPointsInput, $tournamentCategoryId: Int) {
    rankingPoints(filter: $filter, tournamentCategoryId: $tournamentCategoryId) {
      id
      name
      place
      points
      tournamentCategory {
        id
        tournamentCategory {
          id
          name
        }
      }
      rankingCategoryPoints {
        id
        place
        points
      }
    }
  }
`;
export default RANKING_POINTS;
