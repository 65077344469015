import { gql } from '@apollo/client';

const DELETE_TOURNAMENT_RANKINGS = gql`
  mutation removeTournamentRanking($id: Int!) {
    removeTournamentRanking(id: $id) {
      id
    }
  }
`;

export default DELETE_TOURNAMENT_RANKINGS;
