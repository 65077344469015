import { Avatar, AvatarGroup, Box, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import { FC, Fragment } from 'react';
import { Team, TournamentTeamMember } from 'src/__generated__/graphql';
import { externalLinks } from 'src/config';
import { formatSeed } from 'src/constants';
import GetFlag from './GetFlag';
import TeamComponent from './TeamComponent';

interface BaseProps {
  users: TournamentTeamMember[];
  team?: Team;
  showSeed?: boolean;
  isShortName?: boolean;
  highlightWinner?: boolean;
  showAvatar?: boolean;
  showTeamMembers?: boolean;
}

interface SeedProps {
  seed: string | number;
  assignedSegment: string;
  additionalSegment: string;
  segment: string;
}

type TeamMembersProps = BaseProps & (BaseProps['showSeed'] extends true ? SeedProps : Partial<SeedProps>);

const TeamMembers: FC<TeamMembersProps> = ({ users, team, showSeed, isShortName, highlightWinner, showAvatar, showTeamMembers, seed, assignedSegment, additionalSegment, segment }) => {
  return (
    <Fragment>
      {team?.id ? (
        <TeamComponent team={team} users={users} highlightWinner={highlightWinner} showTeamMembers={showTeamMembers} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {showAvatar && <AvatarGroup spacing={24}>{users?.map((user) => <Avatar alt={user?.user?.name} src={user?.user?.avatar} />)}</AvatarGroup>}
          <Typography
            variant="tableFont"
            sx={{
              textTransform: 'uppercase',
              textAlign: 'left',
              mr: '8px',
              display: 'flex !important',
              flexDirection: 'column',
              gap: '8px',
              width: 'max-content',
              fontWeight: highlightWinner ? 'bold' : 'normal',
            }}
          >
            {users?.map((user, index, usersArray) => (
              <Box
                key={user.id}
                component="span"
                sx={{ cursor: 'pointer', display: 'flex', gap: '5px' }}
                onClick={() => {
                  window.open(`${externalLinks.userProfile + user?.user?.id}?token=${Cookies.get('token')}`);
                }}
              >
                <GetFlag country={user?.user?.nation} style={{ width: '1.2em', height: '1.2em' }} />

                {isShortName ? (
                  <Typography
                    variant="tableFont"
                    sx={{
                      fontWeight: highlightWinner ? 'bold' : 'normal',
                    }}
                  >
                    {user?.user?.name?.charAt(0) + '. ' + user?.user?.surname}
                  </Typography>
                ) : (
                  ` ${user?.user?.name || ''} ${user?.user?.surname || ''} `
                )}
                <b>
                  {showSeed &&
                    index === usersArray?.length - 1 &&
                    formatSeed(
                      {
                        seed: seed,
                        assignedSegment: assignedSegment,
                        additionalSegment: additionalSegment,
                      },
                      segment,
                    )}
                </b>
              </Box>
            ))}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default TeamMembers;
