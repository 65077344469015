import { gql } from "@apollo/client";

const UPDATE_MULTIPLE_GROUPS = gql`
  mutation updateMultipleGroups($ids: [Int!]!, $updateGroupInput: UpdateGroupInput!) {
    updateMultipleGroups(
      ids: $ids
      updateGroupInput: $updateGroupInput
    ) {
      id
    }
  }
`;

export default UPDATE_MULTIPLE_GROUPS;