import { gql } from 'src/__generated__/gql';

const GET_RANKINGS_BY_ID = gql(`
  query tournamentRanking($id: Int!) {
    tournamentRanking(id: $id) {
      id
      name
      rankings {
        id
        place
        points
        entry {
          id
          type
          status
          team {
            id
            title
            country
            image
          }
          users {
            id
            user {
              id
              email
              name
              surname
              avatar
              nation
            }
          }
        }
      }
    }
  }
`);
export default GET_RANKINGS_BY_ID;
