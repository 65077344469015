import { gql } from "@apollo/client";
const GroupsTableData = gql`
  query GetGroupsData($filter:ListGroupInput) {
    groups(filter: $filter) {
      id
      name
      # tournament {
      #   id
      #   title
      # }
      # displayPlayOrder
      # displayMatches
      # participantsAddScore
      # entries {
      #   id
      #   registrationRequest {
      #     id
      #     users {
      #       id
      #       user {
      #         email
      #         name
      #         surname
      #       }
      #     }
      #   }
      # }
    }
  }
`;
export default GroupsTableData;