import { gql } from '@apollo/client';

const DELETE_MULTIPLE_TOURNAMENT_RANKINGS = gql`
  mutation removeMultipleTournamentRankingsEntries($ids: [Int!]!) {
    removeMultipleTournamentRankingsEntries(ids: $ids) {
      id
    }
  }
`;

export default DELETE_MULTIPLE_TOURNAMENT_RANKINGS;
