import { gql } from 'src/__generated__/gql';

const UPDATE_MULTIPLE_GROUP_MATCHES = gql(`
  mutation updateMultipleGroupMatches($updateGroupMatchInput: [CreateMatchScoreInput!]!) {
    updateMultipleGroupMatches(updateGroupMatchInput: $updateGroupMatchInput) {
      id
      score
    }
  }
`);

export default UPDATE_MULTIPLE_GROUP_MATCHES;
