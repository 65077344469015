import React, { createContext, useContext, useState, FC, ReactNode } from 'react';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import useQueryParams from 'src/hooks/useQueryParams';

interface DrawsContextProps {
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  // debouncedSearch: string;
  // search: string;
  // setSearch: (search: string) => void;
}

const DrawsContext = createContext<DrawsContextProps | undefined>(undefined);

export const DrawsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getParam } = useQueryParams();
  const [limit, setLimit] = useState<number>(getParam('limit') ? parseInt(getParam('limit') as string) : 8);
  const [page, setPage] = useState<number>(getParam('page') ? parseInt(getParam('page') as string) - 1 : 0);
  // const [search, setSearch] = useState<string>(filterParams.get('search') || '');
  // const debouncedSearch = useDebouncedTerm(search, 500);

  return (
    <DrawsContext.Provider
      value={{
        limit,
        setLimit,
        page,
        setPage,
        // search, debouncedSearch, setSearch
      }}
    >
      {children}
    </DrawsContext.Provider>
  );
};

export const useDraws = (): DrawsContextProps => {
  const context = useContext(DrawsContext);
  if (!context) {
    throw new Error('useDraws must be used within a DrawsProvider');
  }
  return context;
};
