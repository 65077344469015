import { gql } from 'src/__generated__/gql';

const TOURNAMENTS_STATS = gql(`
  query stats($filter: ListLeagueInput) {
    stats(filter: $filter) {
      allTournaments
      passedTournaments
      upcomingTournaments
      activeTournaments
    }
  }
`);

export default TOURNAMENTS_STATS;
