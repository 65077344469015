import { gql } from '@apollo/client';

const CLONE_TOURNAMENT = gql`
  mutation cloneTournament($title: String!, $tournamentId: Float!, $startDate: String!, $endDate: String!, $entryDeadline: String!, $withdrawlDeadline: String!) {
    cloneTournament(title: $title, tournamentId: $tournamentId, startDate: $startDate, endDate: $endDate, entryDeadline: $entryDeadline, withdrawlDeadline: $withdrawlDeadline) {
      title
      id
    }
  }
`;

export default CLONE_TOURNAMENT;
