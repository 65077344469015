import { gql } from '@apollo/client';

const REMOVE_GROUP_ENTRIES = gql`
  mutation removeGroupEntry($ids: [Int!]!, $groupId: Int, $isKingFormat: Boolean) {
    removeGroupEntry(ids: $ids, groupId: $groupId, isKingFormat: $isKingFormat) {
      isDelete
    }
  }
`;

export default REMOVE_GROUP_ENTRIES;
