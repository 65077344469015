import { gql } from '@apollo/client';

const GENERATE_OOP_ALL = gql`
  mutation createOrderOfPlayForAllGroups($groups: [Int!], $date: DateTime!, $time: String!, $duration: Int!, $courts: [Int!], $isEqualCourts: Boolean) {
    createOrderOfPlayForAllGroups(createGroupMatchInput: { groups: $groups, date: $date, time: $time, duration: $duration, courts: $courts, isEqualCourts: $isEqualCourts }) {
      id
      date
      time
      duration
    }
  }
`;

export default GENERATE_OOP_ALL;
