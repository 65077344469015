import { gql } from '@apollo/client';

const ADD_SCORE_DRAW = gql`
  mutation addDrawScore(
    $nextTeam: String
    $nextSeedId: Int
    $winner: Int
    $id: Int!
    $score: String
    $date: DateTime!
    $time: String!
    $court: Int
    $qualWinner: Boolean
    $looserId: Int
    $scoreType: String
    $matchStatus: String # $hideInOOP: Boolean
    $isMatchInProgress: Boolean
  ) {
    addDrawScore(
      updateDrawEntryInput: {
        nextTeam: $nextTeam
        nextSeedId: $nextSeedId
        winner: $winner
        id: $id
        score: $score
        scoreType: $scoreType
        matchStatus: $matchStatus
        date: $date
        time: $time
        court: $court
        qualWinner: $qualWinner
        looserId: $looserId
        isMatchInProgress: $isMatchInProgress
        # hideInOOP: $hideInOOP
      }
    ) {
      id
    }
  }
`;

export default ADD_SCORE_DRAW;
