import { gql } from "@apollo/client";

const ADD_GROUP = gql`
  mutation  createGroup(
    $tournament: Int!
    $tournamentCategory: Int!
    $segment: String!
    $type: String!
    $comment: String!
    $displayPlayOrder: Boolean!
    $displayMatches: Boolean!
    $participantsAddScore: Boolean!
    $totalGroups: Int!
    $naming: String!
    # $seeds: Int
    $algoType: String!
    $name: String
    $groupType: String
  ) {
  createGroup(
    createGroupInput: {
      tournament: $tournament,
      tournamentCategory: $tournamentCategory,
      segment: $segment,
      type: $type,
      comment: $comment,
      displayPlayOrder: $displayPlayOrder,
      displayMatches: $displayMatches,
      participantsAddScore: $participantsAddScore,
      totalGroups: $totalGroups,
      naming: $naming
      # seeds: $seeds
      algoType: $algoType
      name: $name
      groupType: $groupType
    }
  ) {
    id
    name
  }
}
`;

export default ADD_GROUP;
