import { gql } from '@apollo/client';

const ALL_TOURNAMENTS = gql`
  query allTournaments {
    allTournaments(filter: {}) {
      id
      title
      tour
      address
      startDate
      endDate
      country
      courts {
        name
      }
      city
      additionalQuestion {
        id
        question
      }
      league {
        league {
          id
          enableLicense
        }
      }
      coverPhoto
      tournamentCategory {
        id
        category {
          name
          type
        }
        fee
      }
      organizer {
        member {
          name
          surname
        }
      }
      referee {
        referee {
          name
        }
      }
    }
  }
`;

export default ALL_TOURNAMENTS;
