import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexBasis: '88%',
    flexShrink: 0,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  summary: {
    // [theme.breakpoints.down("sm")]: {
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    // },
  },
}));

interface ICustomAccordionProps {
  heading: string;
  secondaryHeading?: string;
  thirdHeading?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

const CustomAccordion: FC<ICustomAccordionProps> = ({ heading, secondaryHeading, thirdHeading, icon, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" className={classes.summary}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
            {icon && icon}
            <Typography className={classes.heading}>{heading}</Typography>
          </Box>
          <Typography className={classes.secondaryHeading}>{secondaryHeading}</Typography>
          {thirdHeading && (
            <Box sx={{ display: 'flex', marginRight: '20px', gap: '5px' }}>
              <Typography className={classes.secondaryHeading}>{thirdHeading}</Typography>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
