import { gql } from '@apollo/client';

const UPDATE_SEGMENTATION = gql`
  mutation updateRegistrationSegment($tournamentId: Float!, $categoryId: Float!, $onlySeed: Boolean) {
    updateRegistrationSegment(tournamentId: $tournamentId, categoryId: $categoryId, onlySeed: $onlySeed) {
      id
      segment
      seed
    }
  }
`;

export default UPDATE_SEGMENTATION;
