import { gql } from '@apollo/client';

const DELETE_LIVE_STANDINGS = gql`
  mutation removeMultipleStandings($ids: [Int!]!) {
    removeMultipleStandings(ids: $ids) {
      title
    }
  }
`;

export default DELETE_LIVE_STANDINGS;
