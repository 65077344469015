import { gql } from '@apollo/client';

const CREATE_LIVE_STANDINGS = gql`
  mutation createLiveStanding($tournament: Int!, $tournamentCategory: Int, $segment: String, $groups: [Int!], $title: String, $type: String, $sorting: String, $limit: Int) {
    createLiveStanding(
      createLiveStandingInput: { tournament: $tournament, tournamentCategory: $tournamentCategory, segment: $segment, groups: $groups, title: $title, type: $type, sorting: $sorting }
      limit: $limit
    ) {
      id
      title
    }
  }
`;

export default CREATE_LIVE_STANDINGS;
