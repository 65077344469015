import { gql } from "@apollo/client";

const DELETE_TOURNAMENT_REQUESTS = gql`
mutation removeTournamentRegistration(
  $registrationIds: [Int!]!
) {
  removeTournamentRegistration(
    ids:$registrationIds
  ) {
    status
  }
}
`;

export default DELETE_TOURNAMENT_REQUESTS;
