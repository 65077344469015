import { gql } from '@apollo/client';

const CREATE_SPORT_FISHING_MATRIC = gql`
  mutation createSportFishingMatric($tournament: Int!, $categoryId: Int!, $limit: Int!, $title: String!, $unit: String!, $sportFishingResult: Int!) {
    createSportFishingMatric(createSportFishingMatricInput: { tournament: $tournament, categoryId: $categoryId, limit: $limit, title: $title, unit: $unit, sportFishingResult: $sportFishingResult }) {
      id
    }
  }
`;

export default CREATE_SPORT_FISHING_MATRIC;
