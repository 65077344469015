import { gql } from '@apollo/client';

const CHANGE_TOURNAMENT_REQUEST_STATUS = gql`
  mutation changeRegistrationStatus($registrationIds: [Int!]!, $status: String!) {
    changeRegistrationStatus(registrationIds: $registrationIds, status: $status) {
      id
      status
    }
  }
`;

export default CHANGE_TOURNAMENT_REQUEST_STATUS;
