import CustomButton from './CustomButton';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';
import { SxProps } from '@material-ui/system';

const CustomAvatar = ({ src, onClick, size = 'full', seed, sx, alt }: { onClick?: () => void; src: string | undefined; size?: number | 'full'; seed?: string; sx?: SxProps; alt?: string }) => {
  const avatar = createAvatar(initials, {
    seed,
    backgroundType: ['gradientLinear'],
    scale: 85,
    // backgroundColor: [
    //   chroma(process.env.REACT_APP_PRIMARY_COLOR).darken(1.5).hex().substring(1).toLowerCase(),
    //   chroma(process.env.REACT_APP_PRIMARY_COLOR).darken(0.5).hex().substring(1).toLowerCase(),
    //   chroma(process.env.REACT_APP_PRIMARY_COLOR).hex().substring(1).toLowerCase(),
    //   chroma(process.env.REACT_APP_PRIMARY_COLOR).brighten(0.5).hex().substring(1).toLowerCase(),
    //   chroma(process.env.REACT_APP_PRIMARY_COLOR).brighten(1.5).hex().substring(1).toLowerCase(),
    // ],
  });
  return (
    <CustomButton
      size="auto"
      shape="circle"
      sx={{
        minHeight: size === 'full' ? '100%' : size + 'px',
        maxHeight: size === 'full' ? '100%' : size + 'px',
        minWidth: size === 'full' ? '100%' : size + 'px',
        maxWidth: size === 'full' ? '100%' : size + 'px',
        overflow: 'hidden',
        ...sx,
      }}
      onClick={onClick}
      variant="text"
    >
      <img alt={alt} src={(src && !src.includes('freepik') && src) || avatar.toDataUri()} style={{ width: size + 'px', height: size + 'px', objectFit: 'cover' }} />
    </CustomButton>
  );
};

export default CustomAvatar;
