import { gql } from '@apollo/client';

const CREATE_GROUP_MATCH = gql`
  mutation createOrderOfPlay($group: Int!, $date: DateTime!, $time: String!, $duration: Int!, $rounds: Int, $oopRounds: [Int!], $courts: [Int!]) {
    createOrderOfPlay(createGroupMatchInput: { group: $group, date: $date, time: $time, duration: $duration, rounds: $rounds, courts: $courts, oopRounds: $oopRounds }) {
      id
      date
      time
      duration
      court {
        name
        # id
      }
    }
  }
`;

export default CREATE_GROUP_MATCH;
