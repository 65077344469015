import { gql } from "@apollo/client";

const DELETE_DRAWS = gql`
  mutation removeMultipleDraws($ids: [Int!]!) {
    removeMultipleDraws(ids: $ids) {
      title
    }
  }
`;

export default DELETE_DRAWS;
