import { Box } from '@material-ui/core';
import data from '../../assets/data-loader-orange.json';
import Lottie from 'lottie-react';
import { FC } from 'react';
// import LottieAnimation from './LottieAnimation';

interface LoadingProps {
  height?: string;
}

const Loading: FC<LoadingProps> = ({ height }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: height || '100vh',
      }}
    >
      {/* <video autoPlay loop muted style={{ width: '80px', height: '80px' }}>
        <source src="/images/loading-light.webm" type="video/mp4" />
      </video> */}
      {/* <LottieAnimation animationData={data} color="blue" /> use this later => pending to fix last image */}
      <Lottie animationData={data} loop={true} style={{ width: '130px', height: '130px' }} />
    </Box>
  );
};

export default Loading;
