import { gql } from "@apollo/client";

const CREATE_LEAGUE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $leagueId: Float!
    $type: String!
    $gender: String!
    $uniqueCode: String!
    $maxTournaments: Float!
    $activeFrom: DateTime!
    $activeTo: DateTime!
    $sort: String!
    $ageLimit: Float!
    $ageLimitType: String!
    $selectStage: String!
    $countries: String!
    $tournamentCategories: [Int!]
  ) {
    createCategory(
      createCategoryInput: {
        name: $name
        leagueId: $leagueId
        type: $type
        gender: $gender
        uniqueCode: $uniqueCode
        maxTournaments: $maxTournaments
        activeFrom: $activeFrom
        activeTo: $activeTo
        sort: $sort
        ageLimit: $ageLimit
        ageLimitType: $ageLimitType
        selectStage: $selectStage
        tournamentCategories: $tournamentCategories
        countries: $countries
      }
    ) {
      id
      name
      type
      gender
      uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
      countries
    }
  }
`;

export default CREATE_LEAGUE_CATEGORY;
