import { gql } from "@apollo/client";

const UPDATE_LEAGUE_CATEGORY = gql`
  mutation updateCategory(
    $id: Int!
    $name: String!
    $type: String!
    $gender: String!
    $uniqueCode: String!
    $maxTournaments: Float!
    $activeFrom: DateTime!
    $activeTo: DateTime!
    $sort: String!
    $ageLimit: Float!
    $ageLimitType: String!
    $selectStage: String!
  ) {
    updateCategory(
      updateCategoryInput: {
        name: $name
        type: $type
        gender: $gender
        uniqueCode: $uniqueCode
        maxTournaments: $maxTournaments
        activeFrom: $activeFrom
        activeTo: $activeTo
        sort: $sort
        ageLimit: $ageLimit
        ageLimitType: $ageLimitType
        selectStage: $selectStage
        id: $id
      }
    ) {
      id
      name
      type
      gender
      uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
    }
  }
`;

export default UPDATE_LEAGUE_CATEGORY;
