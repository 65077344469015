import { gql } from '@apollo/client';

const SHOW_HIDE_STAGE = gql`
  mutation showHideStage($id: Int, $isPublish: Boolean!) {
    updateCourt(updateCourtInput: { id: $id, isPublish: $isPublish }) {
      id
      isPublish
    }
  }
`;

export default SHOW_HIDE_STAGE;
