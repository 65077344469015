import { gql } from "@apollo/client";

const DELETE_DRAW = gql`
  mutation removeDraw(
    $id: Int!
  ) {
    removeDraw(
      id: $id
    ) {
      drawImage
    }
  }
`;

export default DELETE_DRAW;