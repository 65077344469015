import { gql } from '@apollo/client';
const CREATE_RANKING_POINTS = gql`
  mutation createTournamentRanking(
    $tournament: Int!
    $tournamentCategory: Int!
    $name: String
    $rankings: [JSONObject!]
    $liveStanding: Int
    $draw: Int
    $sportFishingResult: Boolean
    $league: Int
    $rankingPoint: Int
  ) {
    createTournamentRanking(
      createTournamentRankingInput: {
        tournament: $tournament
        name: $name
        rankings: $rankings
        tournamentCategory: $tournamentCategory
        liveStanding: $liveStanding
        draw: $draw
        sportFishingResult: $sportFishingResult
        league: $league
        rankingPoint: $rankingPoint
      }
    ) {
      id
      name
    }
  }
`;
export default CREATE_RANKING_POINTS;
