import { Dispatch, FC, SetStateAction, useState, useEffect, Fragment } from 'react';
import { Box, Button, CircularProgress, Divider, Modal, Paper, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Scrollbar from 'src/components/Scrollbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';
import SportChips from '../groupsModal/SportChips';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GROUPS_TABLE_DATA, LIVE_STANDINGS_DATA, TOURNAMENT_BY_ID } from 'src/graphql/queries';
import { CREATE_LIVE_STANDINGS } from 'src/graphql/mutations';
import { useTranslation } from 'react-i18next';
import AutocompleteChip from 'src/components/shared/AutocompleteChip';

interface LiveStandingsModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  //   setLoading: Dispatch<SetStateAction<boolean>>;
}
const LiveStandingsMoodal: FC<LiveStandingsModalProps> = ({
  openModal,
  setOpenModal,
  //   setLoading
}) => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const { t } = useTranslation();
  const auth = useAuth();
  const yupSchema = Yup.object().shape({
    groups: Yup.array().max(255).required(t('Groups is Required Field')),
    standingsTitle: Yup.string().max(255).required(t('Standings Title is Required Field')),
    category: Yup.string().max(255).required(t('Category is Required Field')),
    type: Yup.string().max(255).required(t('Type is Required Field')),
    sorting: Yup.string().max(255).required(t('Sorting is Required Field')),
    limit: Yup.number().min(2, 'Entries Limit must be at least 2').nullable(),
  });

  const typeOptions = [
    {
      label: 'By place & points',
      value: 'byPlacePoints',
    },
    {
      label: 'Multiple Rounds',
      value: 'multiRounds',
    },
  ];

  const sortingOptions = [
    {
      label: 'Place, set ratio, points/games ratio',
      value: 'placeSetRatioPointsGamesRatio',
    },
    {
      label: 'Place, points/games ratio',
      value: 'placePointsGamesRatio',
    },
    {
      label: 'Place, games ratio',
      value: 'placeGamesRatio',
    },
  ];

  const segmentOptions = [
    {
      label: 'Main',
      value: 'MD',
    },
    {
      label: 'Consolation',
      value: 'consolation',
    },
    {
      label: 'Qualification',
      value: 'Q',
    },
  ];

  const [tournamentData, setTournamentData] = useState<any>(null);
  const { data } = useQuery(TOURNAMENT_BY_ID, {
    variables: {
      id: auth.tournamentId,
    },
    onCompleted: (data) => {
      setTournamentData(data?.tournamentDetailById);
    },
  });

  const [category, setCategory] = useState<number>(null);
  const [groups, setGroups] = useState<any>(null);

  const [getGroups, { loading: groupsLoading }] = useLazyQuery(GROUPS_TABLE_DATA, {
    variables: {
      filter: {
        tournament: auth.tournamentId,
        ...(category ? { tournamentCategory: category } : {}),
      },
    },
  });

  const getGroupsData = async () => {
    try {
      const { data } = await getGroups();
      setGroups(data?.groups);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (category) {
      getGroupsData();
    }
  }, [category]);

  const [createLiveStandings, { loading }] = useMutation(CREATE_LIVE_STANDINGS, {
    refetchQueries: ['liveStandings'],
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setOpenModal(false);
    setSelectedGroups([]);
    setCategory(null);
    setGroups([]);
  };

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '512px',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            height: '100%',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              groups: groups || [],
              standingsTitle: '',
              category: category || '',
              segment: '',
              type: '',
              sorting: '',
              limit: '',
            }}
            validationSchema={yupSchema}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
              try {
                if (!values.groups.length) {
                  toast.error('Please select at least one group');
                  return;
                }
                const variables = {
                  tournament: auth.tournamentId,
                  tournamentCategory: parseInt(values.category as string),
                  segment: values.segment,
                  groups: values.groups?.map((group) => group?.id),
                  title: values.standingsTitle,
                  type: values.type,
                  sorting: values.sorting,
                  limit: parseInt(values.limit),
                };

                await createLiveStandings({
                  variables,
                });

                handleClose();
                resetForm();
                toast.success(t('Live standings added successfully'));
              } catch (err) {
                toast.error(err?.message);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, touched, values }): JSX.Element => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Paper
                  elevation={12}
                  sx={{
                    width: '100%',
                    maxHeight: '90vh',
                    background: '#FFF',
                    p: 0.5,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '7px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#aaa',
                      borderRadius: '6px',
                      pl: 3,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#a2a2a2',
                    },
                  }}
                >
                  <Scrollbar>
                    <Box
                      sx={{
                        padding: '20px 20px 0px 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="textPrimary" variant="h2">
                        {t('Add Live Standings')}
                      </Typography>
                      <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
                    </Box>
                    <Box sx={{ padding: '20px 20px 0px 20px' }}>
                      <Divider />
                    </Box>
                    <Box sx={{ padding: '20px 20px 20px 20px' }}>
                      <TextField
                        error={Boolean(touched.category && errors.category)}
                        fullWidth
                        helperText={touched.category && (errors.category as any)}
                        label={t('Select Category')}
                        name="category"
                        onChange={(e) => {
                          setCategory(parseInt(e.target.value));
                          setFieldValue('category', e.target.value);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.category}
                        variant="outlined"
                        sx={{ mt: 2 }}
                      >
                        <option></option>

                        {tournamentData?.tournamentCategory?.length > 0 ? (
                          tournamentData?.tournamentCategory?.map((category) => (
                            <option key={category?.id} value={category?.id}>
                              {category?.category?.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>{t('No category found')}</option>
                        )}
                      </TextField>

                      <TextField
                        error={Boolean(touched.segment && errors.segment)}
                        fullWidth
                        helperText={touched.segment && (errors.segment as any)}
                        label={t('Select segment')}
                        name="segment"
                        onChange={(e) => {
                          setFieldValue('segment', e.target.value);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.segment}
                        variant="outlined"
                        sx={{ mt: 2 }}
                      >
                        <option></option>

                        {tournamentData?.tournamentCategory?.length ? (
                          <Fragment>
                            {segmentOptions.map((segment) => (
                              <option key={segment.value} value={segment.value}>
                                {segment.label}
                              </option>
                            ))}
                          </Fragment>
                        ) : (
                          <option disabled>{t('No segments found')}</option>
                        )}
                      </TextField>

                      <Box sx={{ mt: 4 }} className="category">
                        <AutocompleteChip
                          label={t('Groups')}
                          placeholder={t('Select Groups...')}
                          options={groups || []}
                          value={values.groups}
                          loading={groupsLoading}
                          getOptionLabel={(option) => option?.name}
                          handleChange={(e, newValue) => {
                            setFieldValue('groups', newValue);
                          }}
                          error={Boolean(touched.groups && errors.groups)}
                          helperText={touched.groups && (errors.groups as any)}
                          handleSelectAllChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue('groups', groups);
                            } else {
                              setFieldValue('groups', []);
                            }
                          }}
                        />
                      </Box>

                      <Box sx={{ mt: 3, position: 'relative' }}>
                        <TextField
                          error={Boolean(touched?.standingsTitle && errors.standingsTitle)}
                          fullWidth
                          helperText={touched?.standingsTitle && (errors.standingsTitle as any)}
                          label={t('Standings Title')}
                          name="standingsTitle"
                          onChange={handleChange}
                          value={values.standingsTitle}
                          variant="outlined"
                        />
                        {/* <Typography
                          sx={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translate(0, -50%)",
                            color: "gray",
                          }}
                        >
                          in minutes
                        </Typography> */}

                        <TextField
                          error={Boolean(touched.type && errors.type)}
                          fullWidth
                          helperText={touched.type && (errors.type as any)}
                          label={t('Type')}
                          name="type"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.type}
                          variant="outlined"
                          sx={{ mt: 3 }}
                        >
                          <option></option>

                          {typeOptions.map((type) => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </TextField>

                        <TextField
                          error={Boolean(touched.sorting && errors.sorting)}
                          fullWidth
                          helperText={touched.sorting && (errors.sorting as any)}
                          label={t('Sorting')}
                          name="sorting"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.sorting}
                          variant="outlined"
                          sx={{ mt: 3, mb: 1 }}
                        >
                          <option></option>

                          {sortingOptions.map((type) => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </TextField>

                        <TextField
                          error={Boolean(touched?.limit && errors.limit)}
                          fullWidth
                          helperText={touched?.limit && (errors.limit as any)}
                          label={t('Entries Limit')}
                          name="limit"
                          onChange={handleChange}
                          value={values.limit}
                          variant="outlined"
                          type="number"
                          sx={{ mt: 2 }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: '0 20px 20px',
                        marginBottom: '5px',
                        display: 'flex',
                        gap: '10px',
                        '@media (max-width: 560px)': {
                          flexWrap: 'wrap',
                        },
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          display: 'inline-flex',
                          height: '50px',
                          padding: '10px 24px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          // background: "#29ABE2",
                          color: '#FFF',
                          textTransform: 'uppercase',
                        }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <CircularProgress size={18} color="inherit" sx={{ mr: 1 }} />}
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default LiveStandingsMoodal;
