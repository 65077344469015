import { gql } from '@apollo/client';

const UPDATE_SPORT_FISHING_MATRIC = gql`
  mutation updateSportFishingMatric($id: Int!, $limit: Int!, $title: String!, $unit: String!) {
    updateSportFishingMatric(updateSportFishingMatricInput: { id: $id, limit: $limit, title: $title, unit: $unit }) {
      id
    }
  }
`;

export default UPDATE_SPORT_FISHING_MATRIC;
