import { gql } from "@apollo/client";

const CHANGE_CATEGORY = gql`
  mutation updateTournamentRegistrations($categryId: Int!, $registrationIds: [Int!]!) {
    updateTournamentRegistrations(
      categryId: $categryId
      registrationIds: $registrationIds
    ) {
      id
    }
  }
`;

export default CHANGE_CATEGORY;