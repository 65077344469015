import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT_RANKINGS = gql`
  mutation updateTournamentRanking($id: Int!, $isPublish: Boolean!) {
    updateTournamentRanking(updateTournamentRankingInput: { id: $id, isPublish: $isPublish }) {
      id
      isPublish
    }
  }
`;

export default UPDATE_TOURNAMENT_RANKINGS;
