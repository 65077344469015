import { gql } from "@apollo/client";

const REJECT_TOURNAMENT_REQUESTS = gql`
mutation rejectTournamentRegistrations(
  $registrationIds: [Int!]!
) {
  rejectTournamentRegistrations(
    registrationIds:$registrationIds
  ) {
    id
    status
  }
}
`;

export default REJECT_TOURNAMENT_REQUESTS;
