import { gql } from '@apollo/client';

const CREATE_KING_FORMAT_MATCHES = gql`
  mutation createOrderOfPlayForKingsFormat($tournament: Int!, $groups: [Int!], $courts: [Int!], $duration: Int!, $date: DateTime, $time: String!) {
    createOrderOfPlayForKingsFormat(createGroupMatchInput: { tournament: $tournament, groups: $groups, courts: $courts, duration: $duration, date: $date, time: $time }) {
      id
    }
  }
`;

export default CREATE_KING_FORMAT_MATCHES;
