import { gql } from '@apollo/client';

const UPDATE_USER_PASS = gql`
  mutation updateUserPassword($email: String!, $password: String!) {
    updateUserPassword(updateUserpassword: { email: $email, password: $password }) {
      id
      name
      email
    }
  }
`;

export default UPDATE_USER_PASS;
