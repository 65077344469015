import { gql } from '@apollo/client';

const UPDATE_PAIRING = gql`
  mutation updatePairing($id: Int!, $hide: Boolean, $hideStandings: Boolean) {
    updatePairing(updatePairingInput: { id: $id, hide: $hide, hideStandings: $hideStandings }) {
      hide
      hideStandings
    }
  }
`;

export default UPDATE_PAIRING;
