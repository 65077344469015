import { gql } from '@apollo/client';

const TOURNAMENT_CATEGORY_BY_ID = gql`
  query tournamentCategory($id: Int!) {
    tournamentCategory(id: $id) {
      name
      type
      gender
      order
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      status
      id
    }
  }
`;

export default TOURNAMENT_CATEGORY_BY_ID;
