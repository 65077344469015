import { gql } from '@apollo/client';

const SHOW_HIDE_OOP = gql`
  mutation showHideOOP($tournamentId: Float, $id: Float, $showOOP: Boolean!) {
    updateTournament(updateTournamentInput: { tournamentId: $tournamentId, showOOP: $showOOP, id: $id }) {
      id
      showOOP
    }
  }
`;

export default SHOW_HIDE_OOP;
