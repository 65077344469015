import { useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Card, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, Checkbox, Avatar } from '@material-ui/core';
import SearchIcon from 'src/icons/Search';
import type { TournamentResults } from 'src/types/tournamentResults';
import Scrollbar from 'src/components/Scrollbar';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface TournamentResultsListTableProps {
  leagues: TournamentResults[];
}

type Sort = string;

interface SortOption {
  value: string;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: '13.08.2022',
    value: '13.08.2022',
  },
  {
    label: '14.08.2022',
    value: '14.08.2022',
  },
  {
    label: '15.08.2022',
    value: '15.08.2022',
  },
];

const applyFilters = (leagues: TournamentResults[], query: string, filters: any): TournamentResults[] =>
  leagues?.filter((TournamentResults) => {
    let matches = true;

    if (query) {
      const properties = ['title'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (TournamentResults[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && TournamentResults[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (customers: TournamentResults[], page: number, limit: number): TournamentResults[] => customers.slice(page * limit, page * limit + limit);

const descendingComparator = (a: TournamentResults, b: TournamentResults, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: TournamentResults, b: TournamentResults) => descendingComparator(a, b, orderBy) : (a: TournamentResults, b: TournamentResults) => -descendingComparator(a, b, orderBy);

const applySort = (customers: TournamentResults[], sort: Sort): TournamentResults[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const TournamentResultsListTable: FC<TournamentResultsListTableProps> = (props) => {
  const { leagues, ...other } = props;
  const [selectedTournamentResults, setSelectedTournamentResults] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  const enableBulkActions = selectedTournamentResults.length > 0;
  const selectedSomeTournamentResults = selectedTournamentResults.length > 0 && selectedTournamentResults.length < leagues.length;
  const selectedAllTournamentResults = selectedTournamentResults.length === leagues.length;

  const handleSelectAllTournamentResults = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedTournamentResults(event.target.checked ? leagues.map((TournamentResults) => TournamentResults.id) : []);
  };

  const handleSelectOneDocument = (event: ChangeEvent<HTMLInputElement>, customerId: string): void => {
    if (!selectedTournamentResults.includes(customerId)) {
      setSelectedTournamentResults((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedTournamentResults((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    setQuery('');
  };

  const filteredDocuments = applyFilters(leagues, query, filters);
  const sortedDocuments = applySort(filteredDocuments, sort);
  const paginatedDocuments = applyPagination(sortedDocuments, page, limit);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'end',
          gap: 2,
          m: -1,
          py: 4,
          px: 1,
          '@media (max-width: 768px)': {
            py: 3,
          },
        }}
      >
        <Box
          sx={{
            alignItems: 'end',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: 2,
            m: -1,
            px: 1,
            '@media (max-width: 768px)': {
              mt: '-3px',
              mb: 0,
              justifyContent: 'flex-start',
            },
          }}
        >
          <Box sx={{ minWidth: '170px' }}>
            <TextField
              size="small"
              name="sort"
              sx={{
                width: '100%',
                '& .MuiNativeSelect-select': {
                  fontWeight: 500,
                },
              }}
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              maxWidth: '100%',
              width: 253,
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: 'black' }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search"
              value={query}
              variant="outlined"
            />
          </Box>
          <Box>
            <Button
              size="small"
              color="primary"
              startIcon={
                <ReactSVG
                  src="/images/addw.svg"
                  style={{
                    marginTop: '4px',
                    paddingLeft: '5px',
                  }}
                />
              }
              sx={{ height: '36px', textTransform: 'uppercase', mt: -0.3 }}
              variant="contained"
              onClick={() => navigate('/dashboard/tournament-results/add')}
            >
              Add Results
            </Button>
          </Box>
        </Box>
      </Box>
      <Card
        {...other}
        sx={{
          boxShadow: 'none',
          borderTop: '1px solid #e0e0e0',
          borderRadius: '0px',
        }}
      >
        {enableBulkActions && (
          <Box>
            <Box
              sx={{
                backgroundColor: 'background.paper',
                mt: '6px',
                position: 'absolute',
                px: '4px',
                zIndex: 999,
                top: '15px',
                '@media (max-width: 768px)': {
                  position: 'relative',
                  top: '5px',
                },
              }}
            >
              <Button
                color="primary"
                sx={{
                  marginBottom: '6px',
                  border: '1px solid #E3E3E3',
                  '& svg': {
                    verticalAlign: 'middle',
                  },
                }}
                variant="outlined"
              >
                <ReactSVG src="/images/refresh.svg" />
                <Typography
                  sx={{
                    color: '#182C47',
                    fontWeight: 500,
                    marginLeft: '7px',
                    marginTop: '4px',
                  }}
                  variant="body1"
                >
                  Update
                </Typography>
              </Button>
              <Button
                color="primary"
                sx={{
                  marginBottom: '6px',
                  ml: 2,
                  border: '1px solid #E3E3E3',
                  '& svg': {
                    verticalAlign: 'middle',
                  },
                }}
                variant="outlined"
              >
                <ReactSVG src="/images/hide.svg" />
                <Typography
                  sx={{
                    color: '#182C47',
                    fontWeight: 500,
                    marginLeft: '7px',
                    marginTop: '4px',
                  }}
                  variant="body1"
                >
                  Hide
                </Typography>
              </Button>
              <Button
                color="primary"
                sx={{
                  marginBottom: '6px',
                  ml: 2,
                  border: '1px solid #E3E3E3',
                  '& svg': {
                    verticalAlign: 'middle',
                  },
                }}
                variant="outlined"
              >
                <ReactSVG src="/images/copy.svg" />{' '}
                <Typography
                  sx={{
                    color: '#182C47',
                    fontWeight: 500,
                    marginLeft: '7px',
                    marginTop: '4px',
                  }}
                  variant="body1"
                >
                  Copy
                </Typography>
              </Button>
              <Button
                color="primary"
                sx={{
                  marginBottom: '6px',
                  ml: 2,
                  border: '1px solid #E3E3E3',
                  '& svg': {
                    verticalAlign: 'middle',
                  },
                }}
                variant="outlined"
              >
                <ReactSVG src="/images/trash.svg" />{' '}
                <Typography
                  sx={{
                    color: '#182C47',
                    fontWeight: 500,
                    marginLeft: '7px',
                    marginTop: '4px',
                  }}
                  variant="body1"
                >
                  Delete
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedAllTournamentResults} sx={{ color: '#D3D3D3' }} indeterminate={selectedSomeTournamentResults} onChange={handleSelectAllTournamentResults} />
                  </TableCell>
                  <TableCell width="350px">
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(27, 56, 97, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'pre',
                      }}
                    >
                      PLAYER 1
                    </Typography>
                  </TableCell>
                  <TableCell width="300px">
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(27, 56, 97, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'pre',
                      }}
                    >
                      Country
                    </Typography>
                  </TableCell>
                  <TableCell width="350px">
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(27, 56, 97, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'pre',
                      }}
                    >
                      PLAYER 2
                    </Typography>
                  </TableCell>
                  <TableCell width="300px">
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(27, 56, 97, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'pre',
                      }}
                    >
                      Country
                    </Typography>
                  </TableCell>
                  <TableCell width="100px">
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(27, 56, 97, 0.6)',
                        fontWeight: 500,
                        whiteSpace: 'pre',
                      }}
                    >
                      Points
                    </Typography>
                  </TableCell>
                  <TableCell width="100px" align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedDocuments.map((TournamentResults) => {
                  const isDocumentsSelected = selectedTournamentResults.includes(TournamentResults.id);
                  return (
                    <TableRow hover key={TournamentResults.id} selected={isDocumentsSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={isDocumentsSelected} sx={{ color: '#D3D3D3' }} onChange={(event) => handleSelectOneDocument(event, TournamentResults.id)} value={isDocumentsSelected} />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            sx={{
                              width: '30px',
                              height: '30px',
                              mr: 1,
                            }}
                            src={TournamentResults?.logo}
                          />
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {TournamentResults?.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ReactSVG style={{ marginTop: '5px', marginRight: '8px' }} src="/images/flag-latvia.svg" />
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            Latvia
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            sx={{
                              width: '30px',
                              height: '30px',
                              mr: 1,
                            }}
                            src={TournamentResults?.logo}
                          />
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {TournamentResults?.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ReactSVG style={{ marginTop: '5px', marginRight: '8px' }} src="/images/flag-latvia.svg" />
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            Latvia
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                          12
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton component={RouterLink} to="/dashboard/under-construction" sx={{ padding: 0 }}>
                          <ReactSVG src="/images/pencil.svg" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={filteredDocuments.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </React.Fragment>
  );
};

TournamentResultsListTable.propTypes = {
  leagues: PropTypes.array.isRequired,
};

export default TournamentResultsListTable;
