import { gql } from "@apollo/client";


const FACT_SHEETS = gql`
  query factSheets ($filter:ListFactSheetInput){
  factSheets (filter:$filter) {
    id
    fieldTitle
    fieldType
      league{
      id
    }
    options
  }
}
`;

export default FACT_SHEETS;
