import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

// Type definition for multiple params (key-value pairs)
type QueryParams = Record<string, string | null>;

// Custom Hook
const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get a single or multiple query parameter values
  const getParam = useCallback(
    (keyOrKeys: string | string[]): string | QueryParams | null => {
      if (Array.isArray(keyOrKeys)) {
        // Return an object if multiple keys are passed
        const result: QueryParams = {};
        keyOrKeys.forEach((key) => {
          result[key] = searchParams.get(key);
        });
        return result;
      } else {
        // Return a single value if a single key is passed
        return searchParams.get(keyOrKeys);
      }
    },
    [searchParams],
  );

  // Set or update a single or multiple query parameters
  const setParam = useCallback(
    (keyOrParams: string | QueryParams, value?: string) => {
      const newParams = new URLSearchParams(searchParams);

      if (typeof keyOrParams === 'object') {
        // If an object is passed, set multiple parameters
        Object.keys(keyOrParams).forEach((key) => {
          if (keyOrParams[key] && keyOrParams[key] !== null && keyOrParams[key] !== undefined) {
            newParams.set(key, keyOrParams[key] as string);
          } else {
            newParams.delete(key); // Remove if value is null/undefined
          }
        });
      } else {
        // If a single key-value pair is passed
        if (value && value !== null && value !== undefined) {
          newParams.set(keyOrParams, value);
        } else {
          newParams.delete(keyOrParams);
        }
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams],
  );

  return { getParam, setParam };
};

export default useQueryParams;
