import { gql } from '@apollo/client';

const COURTS = gql`
  query courts($filter: ListCourtInput) {
    courts(filter: $filter) {
      id
      name
      status
      generalLimit
      hideOtherParticipantResults
      individualMetricLimit
      allowParticipantsToAddResults
      attachmentsStatus
    }
  }
`;

export default COURTS;
