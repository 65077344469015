import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, LinearProgress } from '@material-ui/core';

type Item = {
  text: string;
  path: string;
  open: boolean;
  count?: number;
  isShow?: boolean;
};

interface SidebarSubItemsProps {
  subitems: Item[];
  openSubItem: boolean;
  setOpenSubItem?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}

const SidebarSubItems: React.FC<SidebarSubItemsProps> = ({ subitems, openSubItem, setOpenSubItem, isLoading }) => {
  const location = useLocation();
  const isActive = useCallback((pathname: string) => pathname.split('?')[0] === location.pathname, [location.pathname]);
  const memoizedSetOpenSubItem = useMemo(() => setOpenSubItem, [setOpenSubItem]);

  useEffect(() => {
    memoizedSetOpenSubItem(subitems.some((item) => isActive(item.path)));
  }, [subitems, memoizedSetOpenSubItem]);

  return (
    <Collapse
      in={openSubItem}
      timeout="auto"
      unmountOnExit
      sx={{
        '& .MuiCollapse-wrapperInner::before': {
          content: "''",
          borderLeft: '3px solid #86909F',
          width: '2px',
          height: 'calc(100% - 35px)',
          position: 'absolute',
          top: 0,
          left: '-15px',
        },
        '& .MuiCollapse-wrapperInner': {
          marginLeft: !isLoading && '32px',
          padding: isLoading ? '0px 6px' : '0px',
          position: 'relative',
        },
      }}
    >
      {isLoading ? (
        <LinearProgress />
      ) : (
        subitems.map(
          (item, index) =>
            item.isShow && (
              <ListItem
                button
                component={Link}
                to={item.path}
                key={index}
                sx={{
                  '& .MuiListItemText-root::before': item.open && {
                    content: "''",
                    borderRadius: '0px 0px 0px 8px',
                    borderBottom: '3px solid #86909F',
                    borderLeft: '3px solid #86909F',
                    width: '13px',
                    height: '18px',
                    position: 'absolute',
                    top: 5,
                    left: '-15px',
                  },
                  my: '10px',
                  padding: '8px 12px',
                  background: isActive(item.path) ? '#FF5733' : 'transparent',
                  color: isActive(item.path) ? '#FFFFFF' : '#86909F',
                  borderRadius: '8px',
                  height: '40px',
                  '&:hover': {
                    background: '#FF5733',
                    color: '#FFFFFF',
                  },
                }}
              >
                <ListItemText primary={item.text} />
                {item?.count && (
                  <div
                    style={{
                      marginLeft: 'auto',
                      backgroundColor: isActive(item.path) ? '#FFFFFF' : '#FF5733',
                      color: isActive(item.path) ? '#FF5733' : '#FFFFFF',
                      borderRadius: '8px',
                      padding: '0px 8px',
                    }}
                  >
                    {item?.count}
                  </div>
                )}
              </ListItem>
            )
        )
      )}
    </Collapse>
  );
};

export default SidebarSubItems;
