import { gql } from "@apollo/client";

const LEAGUE_CATEGORIES = gql`
  query categories {
    categories(page: 1, limit: 30, filter: {}) {
      id
      name
      type
      gender
      uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
      countries
    }
  }
`;

export default LEAGUE_CATEGORIES;
