import { gql } from 'src/__generated__/gql';

const UPDATE_TOURNAMENT_TEAM_MEMBER_DUPR = gql(`
  mutation updateTournamentTeamMemberDUPR($id: Int!) {
    updateTournamentTeamMemberDUPR(id: $id) {
      id
    }
  }
`);

export default UPDATE_TOURNAMENT_TEAM_MEMBER_DUPR;
