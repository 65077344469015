import { gql } from '@apollo/client';

const TournamentRegistration = gql`
  mutation createTournamentRegistration($type: String!, $tournament: Int!, $categoryId: Int!, $user: [String!], $userId: Int, $status: String!, $request: String!, $termsAndConditions: Boolean!) {
    createTournamentRegistration(
      type: $type
      createTournamentRegistrationInput: { tournament: $tournament, categoryId: $categoryId, user: $user, userId: $userId, status: $status, request: $request, termsAndConditions: $termsAndConditions }
    ) {
      id
      status

      # user {
      #   id
      #   city
      #   user_poster
      # }
    }
  }
`;

export default TournamentRegistration;
