import { gql } from '@apollo/client';

const CREATE_TOURNAMENT_DOCUMENT = gql`
  mutation createTournamentDocument($tournament: Int, $name: String, $section: String, $document: Upload) {
    createTournamentDocument(createTournamentDocumentInput: { tournament: $tournament, name: $name, section: $section, document: $document }) {
      id
      name
      document
    }
  }
`;

export default CREATE_TOURNAMENT_DOCUMENT;
