import { gql } from '@apollo/client';

const FORWARD_BYES = gql`
  mutation forwardByes($entryIds: [JSONObject!]) {
    forwardByes(entryIds: $entryIds) {
      id
    }
  }
`;

export default FORWARD_BYES;
