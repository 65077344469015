import { gql } from "@apollo/client";

const CREATE_DRAWS_OOP = gql`
  mutation createOrderOfPlayDraws(
    $draws: [Int!]!
    $rounds: [String!]
    $time: String!
    $date: DateTime!
    $duration: Int!
    $courts: [Int!]!
  ) {
    createOrderOfPlayDraws(
      updateDrawEntryInput: {
        draws: $draws
        rounds: $rounds
        time: $time
        date: $date
        duration: $duration
        courts: $courts
      }
    ) {
      time
    }
  }
`;

export default CREATE_DRAWS_OOP;