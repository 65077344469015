import { gql } from "@apollo/client";

const CATEGORY_BY_ID = gql`
  query category($id: Int!) {
    category(id: $id) {
      id
      name
      type
      gender
      uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
    }
  }
`;

export default CATEGORY_BY_ID;
