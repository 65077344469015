import React, { ComponentProps, forwardRef, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import CustomButton, { getButtonStyles } from 'src/components/CustomComponents/CustomButton';

type Props = Omit<ComponentProps<typeof CustomButton>, 'shape'> & {
  shape?: ComponentProps<typeof CustomButton>['shape'];
  variant: ComponentProps<typeof CustomButton>['variant'];
  size: ComponentProps<typeof CustomButton>['size'];
  sx?: ComponentProps<typeof CustomButton>['sx'];
  square?: ComponentProps<typeof CustomButton>['square'];
  children: ReactNode;
  color?: string;
} & ComponentProps<typeof Box>;

const CustomChip = forwardRef<HTMLDivElement, Props>(({ shape = 'default', size, variant, children, square, sx, color, ...props }, ref) => (
  <Box
    ref={ref}
    sx={{
      ...getButtonStyles(shape, size, variant, square, color),
      borderRadius: '999px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBlock: 0,
      whiteSpace: 'nowrap',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
));

export default CustomChip;
