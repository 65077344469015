import { gql } from '@apollo/client';

const UPDATE_PAIRING_SCORE = gql`
  mutation updatePairingScore($updatePairingEntryInputs: [UpdatePairingEntryInput!]!, $roundId: Int) {
    updatePairingScore(updatePairingEntryInputs: $updatePairingEntryInputs, roundId: $roundId) {
      score
    }
  }
`;

export default UPDATE_PAIRING_SCORE;
