import { gql } from "@apollo/client";
const CREATE_COURTS = gql`
  mutation createCourt($tournament: Int!, $name: String!) {
    createCourt(createCourtInput: { tournament: $tournament, name: $name }) {
      id
      name
    }
  }
`;
export default CREATE_COURTS;
