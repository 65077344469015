import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT_PARTNERS = gql`
  mutation updatePartner($id: Int!, $title: String, $link: String, $image: Upload) {
    updatePartner(updatePartnerInput: { id: $id, title: $title, link: $link, image: $image }) {
      id
      title
      image
      link
    }
  }
`;

export default UPDATE_TOURNAMENT_PARTNERS;
