import { gql } from "@apollo/client";

const DELETE_ENTRIES = gql`
mutation deleteRequest($id: [Int!]!) {
  deleteRequest(
    id: $id
  ) {
    isDelete
    }
}
`;

export default DELETE_ENTRIES;
