import { gql } from '@apollo/client';

const ADD_KING_GROUPS = gql`
  mutation createKingFormatGroups($filter: ListGroupInput!) {
    createKingFormatGroups(filter: $filter) {
      id
      name
    }
  }
`;

export default ADD_KING_GROUPS;
