import { gql } from '@apollo/client';

const CREATE_TOURNAMENT_PARTNERS = gql`
  mutation createPartner($title: String!, $link: String!, $image: Upload, $tournament: Int!) {
    createPartner(createPartnerInput: { title: $title, link: $link, image: $image, tournament: $tournament }) {
      id
    }
  }
`;

export default CREATE_TOURNAMENT_PARTNERS;
