import { gql } from '@apollo/client';

const CREATE_TOURNAMENT_CATEGORY = gql`
  mutation createTournamentCategory(
    $name: String!
    $type: String!
    $gender: String!
    $categoryMen: Float
    $categoryWomen: Float
    $order: String!
    $enableMetrics: Boolean!
    $team: Float!
    $isAllowed: Boolean!
    $moveToSingles: Boolean!
    $categoryEntries: String!
    $status: String!
  ) {
    createTournamentCategory(
      createTournamentCategoryInput: {
        name: $name
        type: $type
        gender: $gender
        categoryMen: $categoryMen
        categoryWomen: $categoryWomen
        order: $order
        enableMetrics: $enableMetrics
        team: $team
        isAllowed: $isAllowed
        moveToSingles: $moveToSingles
        categoryEntries: $categoryEntries
        status: $status
      }
    ) {
      name
      type
      gender
      order
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      status
      id
    }
  }
`;

export default CREATE_TOURNAMENT_CATEGORY;
