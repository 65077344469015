import { gql } from '@apollo/client';

const CREATE_PAIRiNG = gql`
  mutation createPairing($tournament: Int!, $tournamentCategory: Int!, $type: String!, $size: Int) {
    createPairing(createPairingInput: { tournament: $tournament, tournamentCategory: $tournamentCategory, type: $type, size: $size }) {
      id
    }
  }
`;

export default CREATE_PAIRiNG;
