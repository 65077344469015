import { gql } from '@apollo/client';

const GET_ALL_PARTNERS = gql`
  query partners($page: Int, $limit: Int, $filter: ListPartnerInput) {
    partners(page: $page, limit: $limit, filter: $filter) {
      id
      title
      image
      link
    }
  }
`;

export default GET_ALL_PARTNERS;
