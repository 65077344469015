import { gql } from "@apollo/client";

const APPROVE_TOURNAMENT_REQUESTS = gql`
mutation approveTournamentRegistrations(
  $registrationIds: [Int!]!
) {
  approveTournamentRegistrations(
    registrationIds:$registrationIds
  ) {
    id
    status
  }
}

`;

export default APPROVE_TOURNAMENT_REQUESTS;
