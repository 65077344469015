import { gql } from "@apollo/client";

const UPLOAD_CSV_SINGLE = gql`
  mutation createRecordsForSingle(
    $csvFile: Upload!
    $tournament: Int!
    $categoryId: Int!
    $type: String!
  ) {
    createRecordsForSingle(
      createTournamentRegistrationInput: {
        csvFile: $csvFile
        tournament: $tournament
        categoryId: $categoryId
        type: $type
      }
    )
  }
`;

export default UPLOAD_CSV_SINGLE;
