import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import Loading from 'src/components/shared/Loading';
import { useTournamentPreferences } from 'src/hooks/useTournamentPreferences';

interface ProtectedRouteProps {
  children: React.ReactNode;
  check: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, check }) => {
  const preferences = useTournamentPreferences();

  if (preferences.isLoadingPreferences || !Object.keys(preferences).length) {
    return <Loading />;
  }

  return preferences && preferences[check] ? children : <Navigate to="/dashboard" />;
};

export default ProtectedRoute;
