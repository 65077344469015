import { gql } from '@apollo/client';

const TOUR_CATEGORIES = gql`
  query tourCategories($filter: ListTournamentCategoryInput) {
    tournamentCategories(filter: $filter) {
      name
      type
      gender
      order
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      enableLicense
      status
      ageLimit
      ageType
      id
      timeControl
    }
  }
`;

export default TOUR_CATEGORIES;
