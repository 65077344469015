import { gql } from "@apollo/client";

const LEAGUE_MEMBERS = gql`
  query leagueMembers($filter: ListLeagueMemberInput) {
    leagueMembers(page: 1, limit: 50, filter: $filter) {
      id
      member {
        email
        name
        surname
        dob
        email
        nation
        phone
        role
        id
      }
      role {
        role {
          name
        }
      }
    }
  }
`;

export default LEAGUE_MEMBERS;
