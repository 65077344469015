import { gql } from '@apollo/client';

const USER_BY_ID = gql`
  query user($id: Float!) {
    user(query: { id: $id }) {
      name
      surname
      email
      phone
      nation
      gender
      city
      street
      user_club_text
      role
      password
      dob
      id
    }
  }
`;

export default USER_BY_ID;
