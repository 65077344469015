import { gql } from "@apollo/client";

const HIDE_UNHIDE_ALL_GROUPS = gql`
  mutation hideUnhideAllGroups($tournament: Int!, $hide: Boolean!) {
    hideUnhideAllGroups(
      tournament: $tournament
      hide: $hide
    ) {
      id
    }
  }
`;

export default HIDE_UNHIDE_ALL_GROUPS;