import { gql } from '@apollo/client';

const REMOVE_PAIRING_ENTRY = gql`
  mutation removePairingEntry($id: Int!) {
    removePairingEntry(id: $id) {
      id
    }
  }
`;

export default REMOVE_PAIRING_ENTRY;
