import { countryToAlpha2 } from 'country-to-iso';
import { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function GetFlag({ country, style, imgStyle = {} }) {
  const isCountryNumber = Number(country);
  const code = countryToAlpha2(!Number.isNaN(isCountryNumber) ? '' : country);

  const fallbackImageSrc = '/images/flags/noFlag.svg';

  const imageSrc = code ? `/images/flags/${code.toLowerCase()}.svg` : fallbackImageSrc;

  return (
    // set stylattribute to div
    <LazyLoadImage
      alt={country}
      // height={15}
      src={imageSrc}
      // width={15}
      style={{
        width: '100%',
        height: '100%',
        ...imgStyle,
      }}
      effect="opacity"
      wrapperProps={{
        style: {
          display: 'inline-block',
          ...style,
        },
      }}
    />
  );
}

export default memo(GetFlag);
