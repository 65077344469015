import { gql } from "@apollo/client";

const DELETE_GROUPS = gql`
  mutation removeMultipleGroups($ids: [Int!]!) {
    removeMultipleGroups(ids: $ids) {
      name
    }
  }
`;

export default DELETE_GROUPS;
