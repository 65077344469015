import { gql } from "@apollo/client";

const REMOVE_DRAW = gql`
  mutation removeTournamentDraw($tournament: Int!) {
    removeTournamentDraw(
        tournament: $tournament
    ) {
        drawImage
    }
}
`;

export default REMOVE_DRAW;
