import { gql } from '@apollo/client';

const LEAGUES = gql`
  query leagues {
    leagues(page: 1, limit: 50, filter: {}) {
      title
      type
      status
      sports {
        id
        title
      }
      email
      website
      id
      logo
      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default LEAGUES;
