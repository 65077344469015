import { Box, Button, Typography } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import React from 'react';
interface NoData {
  btnTitle?: String;
  noText?: String;
  link?: any;
}
const NoData = (props: NoData) => {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.btnTitle && (
          <Button
            size="small"
            color="primary"
            startIcon={
              <ReactSVG
                src="/images/addw.svg"
                style={{
                  marginTop: '4px',
                  paddingLeft: '5px',
                }}
              />
            }
            sx={{
              height: '36px',
              textTransform: 'uppercase',
              mt: -0.3,
            }}
            variant="contained"
            onClick={props.link}
          >
            {props.btnTitle}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
        <Typography variant="h2" sx={{ fontSize: { md: '21px', xs: '16px' } }}>
          {props.noText}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default NoData;
