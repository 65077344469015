import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT_TEAM_MEMBER = gql`
  mutation updateTournamentTeamMember($id: Int!, $rapidElo: String, $standardElo: String, $blitzElo: String, $fideTitle: String, $rank: Int) {
    updateTournamentTeamMember(updateTournamentTeamMemberInput: { id: $id, rapidElo: $rapidElo, standardElo: $standardElo, blitzElo: $blitzElo, fideTitle: $fideTitle, rank: $rank }) {
      rank
    }
  }
`;

export default UPDATE_TOURNAMENT_TEAM_MEMBER;
