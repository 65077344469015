import { gql } from '@apollo/client';
const CAPTAIN_TEAMS = gql`
  query captainsTeam($id: Int!, $tournamentId: Int) {
    captainTeams(id: $id, tournamentId: $tournamentId) {
      id
      title
      image
      members {
        member {
          id
          email
        }
        team {
          title
        }
        role
      }
    }
  }
`;
export default CAPTAIN_TEAMS;
