import { gql } from '@apollo/client';

const UPDATE_REGISTRATIONS_RANKINGS = gql`
  mutation updateRegistrationsRankings($tournamentId: Float!, $categoryId: Float!, $ids: [Int!]!) {
    updateRegistrationsRankings(tournamentId: $tournamentId, categoryId: $categoryId, ids: $ids) {
      id
      segment
      seed
    }
  }
`;

export default UPDATE_REGISTRATIONS_RANKINGS;
