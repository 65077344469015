import { useQuery } from '@apollo/client';
import useAuth from './useAuth';
import { TOURNAMENT_PREFERENCES } from 'src/graphql/queries';

export const useResourceTitle = () => {
  const { leagueId } = useAuth();
  const { data } = useQuery(TOURNAMENT_PREFERENCES, { skip: !leagueId, variables: { id: leagueId } });

  return data?.league?.sports?.resourceTitle || 'Court';
};
