import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT_CATEGORY = gql`
  mutation updateTournamentCategory(
    $id: Int!
    $name: String!
    $type: String!
    $gender: String!
    $order: String!
    $enableMetrics: Boolean!
    $team: Float!
    $isAllowed: Boolean!
    $moveToSingles: Boolean!
    $categoryEntries: String!
    $status: String!
  ) {
    updateTournamentCategory(
      updateTournamentCategoryInput: {
        id: $id
        name: $name
        type: $type
        gender: $gender
        order: $order
        enableMetrics: $enableMetrics
        team: $team
        isAllowed: $isAllowed
        moveToSingles: $moveToSingles
        categoryEntries: $categoryEntries
        status: $status
      }
    ) {
      name
      type
      gender
      order
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      status
      id
    }
  }
`;

export default UPDATE_TOURNAMENT_CATEGORY;
