import { gql } from '@apollo/client';

const TOURNAMENT_REGISTRATION_BY_ID = gql`
  query tournamentRegistration($id: Int!) {
    tournamentRegistration(id: $id) {
      id
      additionalQuestions {
        id
        question {
          id
          question
          fieldOptions
        }
        answer
        file
        fieldType
        amount
        user {
          id
          email
          name
          surname
        }
      }
    }
  }
`;

export default TOURNAMENT_REGISTRATION_BY_ID;
