import { gql } from '@apollo/client';

const USERS = gql`
  query users($filter: ListUsersInput) {
    users(page: 1, limit: 10, filter: $filter) {
      users {
        email
        role
        id
        name
      }
    }
  }
`;

export default USERS;
