import { gql } from 'src/__generated__/gql';

const GET_RANKINGS = gql(`
  query tournamentRankings($filter: ListTournamentRankingInput!, $tournamentCategoryIds: [Int!]) {
    tournamentRankings(filter: $filter, tournamentCategoryIds: $tournamentCategoryIds) {
      tournamentRankings {
        id
        name
        isPublish
        tournament {
          id
          title
        }
        tournamentCategory {
          id
          category {
            type
            name
            id
            identifyTeamMember
          }
        }
        # segment
        rankings {
          createdAt
          updatedAt
          id
          place
          points
          entry {
            id
            # registrationRequest {
            team {
              id
              title
              image
            }
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
          }
          # }
        }
      }
    }
  }
`);
export default GET_RANKINGS;
