import { gql } from '@apollo/client';

const SPORTS_FISHING_MATRICS = gql`
  query sportFishingMatrics($filter: ListSportFishingMatricInput) {
    sportFishingMatrics(filter: $filter) {
      id

      sportFishingResult {
        id
        stage {
          id
          name
        }
      }
      categoryId {
        id
      }
      limit
      title
      unit
    }
  }
`;

export default SPORTS_FISHING_MATRICS;
