import { gql } from '@apollo/client';

const DRAW_BY_ID = gql`
  query draw($id: Int!) {
    draw(id: $id) {
      id
      drawImage
      fileName
      tournamentCategory {
        id
        category {
          name
          id
        }
      }
      drawEntries {
        round
        id
        entry1 {
          users {
            user {
              id
              name
              surname
            }
          }
        }
        entry2 {
          users {
            user {
              id
              name
              surname
            }
          }
        }
      }
      title
      segment
      type
      size
      qualWinners
      place
      algo
      genDraw
      hide
      is24DoubleEliminator
    }
  }
`;

export default DRAW_BY_ID;
