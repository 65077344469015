import { gql } from '@apollo/client';

const DELETE_SPORT_FISHING_MATRIC = gql`
  mutation removeSportFishingMatric($id: Int!) {
    removeSportFishingMatric(id: $id) {
      title
    }
  }
`;

export default DELETE_SPORT_FISHING_MATRIC;
