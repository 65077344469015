import { gql } from 'src/__generated__/gql';

const SWAP_DRAW_MATCHES = gql(`
  mutation swapDrawMatches($match1: Int!, $match2: Int!) {
    swapDrawMatches(match1: $match1, match2: $match2) {
      id
    }
  }
`);

export default SWAP_DRAW_MATCHES;
