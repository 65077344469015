import { gql } from '@apollo/client';

const UPDATE_DUPR_RATING = gql`
  mutation updateDUPRRating($tournamentId: Float!, $categoryId: Float!) {
    updateDUPRRating(tournamentId: $tournamentId, categoryId: $categoryId) {
      id
    }
  }
`;

export default UPDATE_DUPR_RATING;
