import { gql } from '@apollo/client';

const UPDATE_STAGE_ENTRY = gql`
  mutation updateSportFishingResultEntry($id: Int!, $weight: Int, $location: String, $stage: Int, $entry: Int) {
    updateSportFishingResultEntry(updateSportFishingResultEntryInput: { id: $id, weight: $weight, location: $location, stage: $stage, entry: $entry }) {
      weight
    }
  }
`;

export default UPDATE_STAGE_ENTRY;
