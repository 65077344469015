import { gql } from '@apollo/client';

const REMOVE_TOURNAMENT_DOCUMENT = gql`
  mutation removeTournamentDocument($id: Int!) {
    removeTournamentDocument(id: $id) {
      name
    }
  }
`;

export default REMOVE_TOURNAMENT_DOCUMENT;
