import { gql } from "@apollo/client";

const CHANGE_MULTIPLE_CATEGORIES_SETTINGS = gql`
  mutation updateCategorySettings($categryIds: [Int!]!, $updateCategorySettingInput: UpdateCategorySettingInput!) {
    updateCategorySettings(
      categryIds: $categryIds
      updateCategorySettingInput: $updateCategorySettingInput
    ) {
      id
    }
  }
`;

export default CHANGE_MULTIPLE_CATEGORIES_SETTINGS;