import { gql } from '@apollo/client';

const SHOW_HIDE_LIST_RESULTS = gql`
  mutation changeResultPublishStatus($tournament: Int, $categoryId: Int, $isPublish: Boolean!) {
    changeResultPublishStatus(updateSportFishingResultInput: { tournament: $tournament, isPublish: $isPublish, categoryId: $categoryId }) {
      id
      isPublish
    }
  }
`;

export default SHOW_HIDE_LIST_RESULTS;
