import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, CircularProgress } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import gtm from 'src/lib/gtm';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ALL_LEAGUES, TOURNAMENT_BY_ID } from 'src/graphql/queries';
import { LEAGUES } from 'src/graphql/queries';
import TournamentResultsListTable from 'src/components/dashboard/TournamentResults/TournamentResultsListTable';
import { Breadcrumb } from 'src/components/dashboard/breadcrumb';
import TopbarWrapper from 'src/components/dashboard/topbarWrapper/TopbarWrapper';
import React from 'react';
import NoData from 'src/components/dashboard/datatable/NoData';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const TournamentResults: FC = () => {
  const { tournamentId } = useAuth();
  const navigate = useNavigate();
  const [getTournament] = useLazyQuery(TOURNAMENT_BY_ID);
  const [tournamentData, setTournamentData] = useState<any>({});
  const { settings } = useSettings();
  const { loading, data } = useQuery(ALL_LEAGUES);
  useEffect(() => {
    getTournament({
      variables: {
        id: tournamentId,
      },
      onCompleted: (data) => {
        setTournamentData(data?.tournamentDetailById);
      },
    });

    gtm.push({ event: 'page_view' });
  }, [getTournament, tournamentId]);
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard: Pending Results Data</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: '12px',
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Breadcrumb secondCrumb="Pending Results" secondLink="/dashboard/pending-results" thirdCrumb={tournamentData.title} thirdLink="/dashboard/pending-results" />
          <TopbarWrapper image={tournamentData.coverPhoto} description={tournamentData.title} title="Pending Results" linkOne="/dashboard/all-tournaments" btnOne="Back to tournament" />
          <Box sx={{ position: 'relative' }}>
            {data?.allLeaguesNames.length > 0 ? (
              <TournamentResultsListTable leagues={data?.allLeaguesNames} />
            ) : (
              <Box sx={{ mt: 3 }}>
                <NoData link={() => navigate('/dashboard/tournamentResults/add')} btnTitle="Add Pending Results" noText="There is no pending results" />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default TournamentResults;
