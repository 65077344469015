import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Grid, Link } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BreadcrumbProps {
  secondCrumb?: string;
  secondLink?: string;
  thirdCrumb?: string;
  thirdLink?: string;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" spacing={3} mb={1}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb" separator={'/'}>
            <Link sx={{ color: '#1b386199' }} component={RouterLink} to="/dashboard/all-tournaments" variant="subtitle2">
              {t('Home')}
            </Link>
            <Link sx={{ color: '#1b386199' }} component={RouterLink} to={props.secondLink} variant="subtitle2">
              {props.secondCrumb}
            </Link>
            <Link sx={{ color: '#19366066' }} component={RouterLink} to={props.thirdLink} variant="subtitle2">
              {props.thirdCrumb}
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Breadcrumb;
