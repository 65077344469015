import { gql } from '@apollo/client';

const UPDATE_COURT = gql`
  mutation updateCourt(
    $id: Int!
    # $tournament: Int!
    $status: String
    $name: String
    $generalLimit: Int
    $hideOtherParticipantResults: Boolean
    $individualMetricLimit: Boolean
    $allowParticipantsToAddResults: Boolean
    $attachmentsStatus: String
  ) {
    updateCourt(
      updateCourtInput: {
        id: $id
        # tournament: $tournament
        name: $name
        status: $status
        generalLimit: $generalLimit
        hideOtherParticipantResults: $hideOtherParticipantResults
        individualMetricLimit: $individualMetricLimit
        allowParticipantsToAddResults: $allowParticipantsToAddResults
        attachmentsStatus: $attachmentsStatus
      }
    ) {
      id
    }
  }
`;

export default UPDATE_COURT;
