import { gql } from '@apollo/client';
const GET_LIVE_STANDINGS_IDS = gql`
  query liveStandingsIds($filter: ListLiveStandingsInput) {
    liveStandings(filter: $filter) {
      id
      title
    }
  }
`;
export default GET_LIVE_STANDINGS_IDS;
