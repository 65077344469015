import { gql } from '@apollo/client';

const REGISTER = gql`
  mutation signUp($email: String!, $name: String!, $surname: String!, $password: String!, $user_sport: String!, $role: String!) {
    signUp(createUserInput: { email: $email, name: $name, surname: $surname, password: $password, user_sport: $user_sport, role: $role }) {
      email
      accessToken
      name
      surname
      role
      user_sport
    }
  }
`;

export default REGISTER;
