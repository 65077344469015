import { gql } from "@apollo/client";

const RECOVER_GROUP = gql`
  mutation recoverSingleGroup($id: Int!) {
    recoverSingleGroup(id: $id) {
      # comment
      isDelete
      id
    }
  }
`;

export default RECOVER_GROUP;
