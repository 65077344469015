import type { FC } from 'react';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField, Checkbox, Typography, Link, CircularProgress } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { makeStyles } from '@material-ui/styles';
import { ReactSVG } from 'react-svg';
import { LOGIN } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ResetPassModal from './ResetPassModal';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '60%',
    right: '18px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
}));

const LoginJWT: FC = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const mounted = useMounted();
  const { login } = useAuth() as any;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginUser, { data, loading, error }] = useMutation(LOGIN) as any;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<any>(null);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
          const variables = {
            email: values.email,
            password: values.password,
          };
          try {
            setCredentials(variables);
            const response = await loginUser({
              variables,
            });

            // setOpenModal(true);

            await login(response.data.tournamentLogin);

            // if (mounted.current) {
            setStatus({ success: true });
            navigate('/dashboard');
            setSubmitting(false);
            // }
          } catch (error) {
            // if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
            // }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <TextField
              autoFocus
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <Box className={classes.passwordContainer}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onChange={handleChange}
                type={isPasswordVisible ? 'text' : 'password'}
                value={values.password}
                variant="outlined"
              />
              <Box className={classes.passwordToggleBtn} onClick={togglePasswordVisibility}>
                {isPasswordVisible ? <ReactSVG src="/images/eye-close.svg" /> : <ReactSVG src="/images/eye-open.svg" />}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox />
                <Typography variant="body1">Remember me</Typography>
              </Box>
              <Link color="textSecondary" component={RouterLink} to="/authentication/password-recovery">
                <Typography variant="body1" sx={{ color: 'gray', cursor: 'pointer' }}>
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{String(errors.submit)}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                {isSubmitting && <CircularProgress size={18} color="inherit" sx={{ mr: 1 }} />}
                {isSubmitting ? 'Logging in...' : 'Log in'}
              </Button>
            </Box>
            {/* <Box sx={{ mt: 2 }}>
              <Alert severity="info">
                <Box>
                  Use <b>demo@devias.io</b> and password <b>Password123!</b>
                </Box>
              </Alert>
            </Box> */}
          </form>
        )}
      </Formik>
      <ResetPassModal openModal={openModal} setOpenModal={setOpenModal} credentials={credentials} />
    </Fragment>
  );
};

export default LoginJWT;
