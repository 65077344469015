import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { DELETE_LIVE_STANDINGS } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import useAuth from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import WarningPopup from 'src/components/shared/WarningPopup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: '50%',
    transform: 'translateX(50%)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    '@media (max-width: 560px)': {
      flexWrap: 'wrap',
    },
  },
  button: {
    '@media (max-width: 560px)': {
      width: '100%',
      height: 'initial !important',
    },
  },
}));

interface Props {
  selectedLiveStandings: number[];
  setSelectedLiveStandings: Dispatch<SetStateAction<[]>>;
}

const ActionButtons: FC<Props> = ({ selectedLiveStandings, setSelectedLiveStandings }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();

  const [deleteLiveStandings, { loading }] = useMutation(DELETE_LIVE_STANDINGS, {
    refetchQueries: ['liveStandings'],
    awaitRefetchQueries: true,
  });

  const handleDelete = async () => {
    setOpenDeleteModal(false);
    toast.promise(
      deleteLiveStandings({
        variables: {
          ids: selectedLiveStandings,
        },
      }),
      {
        loading: t('Deleting live standings...'),
        success: () => {
          setSelectedLiveStandings([]);
          return t('Live standings deleted successfully.');
        },
        error: (err) => `${err.message}`,
      },
    );
  };

  const classes = useStyles();

  return (
    <Box className={classes.buttonContainer}>
      <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => setOpenDeleteModal(true)} className={classes.button} disabled={loading}>
        {`${t('Delete Selected Live Standings')} (${selectedLiveStandings.length})`}
      </Button>
      <WarningPopup
        openWarningModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleAction={handleDelete}
        warningTitle={t('Confirm Deletion')}
        warningText={t('deleteLivesStandingsWarning')}
        confirmText={t('Delete')}
      />
    </Box>
  );
};

export default ActionButtons;
