import { gql } from "@apollo/client";

const UPDATE_ENTRIES = gql`
  mutation updateRegistrationRequest(
    $tournament: Int!
    $type: String!
    $status: String!
    $categoryId: Int!
    $usersData: [JSONObject!]
    $user: [String!]
    $id: Float!
  ) {
    updateRegistrationRequest(
      id: $id
      createTournamentRegistrationInput: {
        tournament: $tournament
        type: $type
        status: $status
        categoryId: $categoryId
        usersData: $usersData
        user: $user
      }
    ) {
      type
      status
    }
  }
`;

export default UPDATE_ENTRIES;
