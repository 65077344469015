import { gql } from '@apollo/client';
const CATEGORY_SETTINGS = gql`
  query categorySettings($filter: ListCategorySettingInput) {
    # categorySettings(page: 1, limit: 20, filter: $filter) {
    categorySettings(filter: $filter) {
      id
      fee
      color
      onsiteFee
      vatAmount
      isVATAmount
      method
      onlinePayments
      registrationLimit
      rounds
      time
      timePerMove
      timeAfterMove
      extraTime
      pairing
      status
      service
      currency
      mde
      mdwc
      qe
      qwc
      mds
      qs
      consolation
      category {
        id
        name
      }
    }
  }
`;
export default CATEGORY_SETTINGS;
