import { gql } from '@apollo/client';

const UPDATE_PAIRING_ENTRY = gql`
  mutation updatePairingEntry($id: Int!, $entry1: Int, $entry2: Int) {
    updatePairingEntry(updatePairingEntryInput: { id: $id, entry1: $entry1, entry2: $entry2 }) {
      id
    }
  }
`;

export default UPDATE_PAIRING_ENTRY;
