import { gql } from "@apollo/client";

const COPY_REQUESTS = gql`
  mutation copyTournamentRegistration(
    $tournament: Int!
    $type: String!
    $status: String!
    $categoryId: Int!
    $registrationRequests: [JSONObject!]
  ) {
    copyTournamentRegistration(
      createTournamentRegistrationInput: {
        tournament: $tournament
        type: $type
        status: $status
        categoryId: $categoryId
        registrationRequests: $registrationRequests
      }
    ) {
      id
    }
  }
`;

export default COPY_REQUESTS;
