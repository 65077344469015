import { gql } from '@apollo/client';

const ME = gql`
  query getProfile {
    getProfile {
      id
      avatar
      email
      name
      surname
      role
      user_sport
      nation
      plans {
        id
      }
    }
  }
`;

export default ME;
