import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';

interface AuthLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftSection: {
    width: '69%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  rightSection: {
    background: `url('/images/bglayer.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
}));

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.rightSection}>{children || <Outlet />}</div>;
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
