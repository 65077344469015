import { gql } from "@apollo/client";

const REMOVE_GROUP = gql`
  mutation removeGroup($id: Int!) {
    removeGroup(
        id: $id
    ) {
        comment
    }
}
`;

export default REMOVE_GROUP;
