import { gql } from '@apollo/client';

const UPDATE_DRAW = gql`
  mutation updateDraw(
    $id: Int!
    $title: String
    $tournamentCategory: Int
    $thirdPlaceDraw: Boolean
    $type: String
    $tournament: Int
    $hide: Boolean
    $isDisplayParticipantsClub: Boolean
    $isDistributeClubMembers: Boolean
  ) {
    updateDraw(
      updateDrawInput: {
        id: $id
        title: $title
        tournamentCategory: $tournamentCategory
        thirdPlaceDraw: $thirdPlaceDraw
        type: $type
        tournament: $tournament
        hide: $hide
        isDisplayParticipantsClub: $isDisplayParticipantsClub
        isDistributeClubMembers: $isDistributeClubMembers
      }
    ) {
      id
      hide
    }
  }
`;

export default UPDATE_DRAW;
