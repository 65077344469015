import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ReactSVG } from 'react-svg';
import { Badge, Menu } from '@material-ui/core';
import SidebarSubItems from './SidebarSubItems';
import { Link, useLocation } from 'react-router-dom';

type subItems = {
  open: boolean;
  text: string;
  path: string;
  isShow?: boolean;
  count?: number;
};

interface SidebarItemProps {
  icon: string;
  text: string;
  open: boolean;
  isLoading?: boolean;
  subitems?: subItems[];
}

const SidebarItem: FC<SidebarItemProps> = ({ icon, text, open, subitems, isLoading }) => {
  const [openSubItem, setOpenSubItem] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const location = useLocation();
  const isActive = subitems?.some((item: any) => item.path?.split('?')[0] === location.pathname);
  const isActiveSubItem = useMemo(() => (pathname: string) => pathname.split('?')[0] === location.pathname, [location.pathname]);

  useEffect(() => {
    setOpenSubItem(false);
  }, [open]);

  return (
    <Fragment>
      {open ? (
        <Fragment>
          <ListItem
            button
            sx={{
              width: '100%',
              height: '40px',
              mb: '20px',
              mt: '10px',
              padding: '10px 12px 10px 12px',
              borderRadius: '8px',
              background: openSubItem && '#FAFAFA',
              border: openSubItem && '1px solid #EDF1F6',
              color: openSubItem && '#0A2540',
            }}
            onClick={() => setOpenSubItem(!openSubItem)}
          >
            <ListItemIcon sx={{ marginRight: !open ? 0 : '16px' }}>
              <ReactSVG
                src={icon}
                style={{ marginTop: '5px' }}
                beforeInjection={(svg) => {
                  svg.children[0].setAttribute(openSubItem && 'style', 'fill: #0A2540');
                }}
              />
            </ListItemIcon>
            {open && <ListItemText primary={text} sx={{ '& .MuiListItemText-primary': { fontWeight: 500 } }} />}
            {open && subitems && (openSubItem ? <ExpandLess style={{ fill: '#0A2540' }} /> : <ExpandMore style={{ fill: '#86909F' }} />)}
          </ListItem>
          {subitems && <SidebarSubItems subitems={subitems} openSubItem={openSubItem} setOpenSubItem={setOpenSubItem} isLoading={isLoading} />}
        </Fragment>
      ) : (
        <Fragment>
          <ListItem
            button
            sx={{
              width: '100%',
              height: '40px',
              my: '20px',
              padding: '10px 12px 10px 12px',
              borderRadius: '8px',
              background: openSubItem || isActive ? '#FF5733' : '#FAFAFA',
              border: openSubItem && '1px solid #EDF1F6',
              justifyContent: !open && 'center',
              alignItems: !open && 'center',
              '&:hover': {
                background: isActive ? '#FF5733' : '#FAFAFA',
              },
            }}
            onClick={(e) => {
              setOpenSubItem(!openSubItem);
              setAnchorEl(e.currentTarget);
            }}
            id="demo-positioned-button"
            aria-controls={openSubItem ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openSubItem ? 'true' : undefined}
          >
            {/* <Badge badgeContent={100} sx={{ "& .MuiBadge-badge": { top: "-7px", right: "-10px", background: "#FF5733", color: "#FFFF" } }}> */}
            <ListItemIcon sx={{ marginRight: !open ? 0 : '16px' }}>
              <ReactSVG
                src={icon}
                style={{ marginTop: '5px' }}
                beforeInjection={(svg) => {
                  svg.children[0].setAttribute((openSubItem || isActive) && 'style', 'fill: #FFFF');
                }}
              />
            </ListItemIcon>
            {/* </Badge> */}
          </ListItem>
          {subitems && (
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openSubItem}
              onClose={() => setOpenSubItem(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPaper-root': {
                  left: '86px !important',
                  padding: '10px',
                },
              }}
            >
              {subitems.map(
                (item) =>
                  item.isShow && (
                    <ListItem
                      button
                      component={Link}
                      to={item.path}
                      sx={{
                        '& .MuiListItemText-root::before': item.open && {
                          content: "''",
                          borderRadius: '0px 0px 0px 8px',
                          borderBottom: '3px solid #86909F',
                          borderLeft: '3px solid #86909F',
                          width: '13px',
                          height: '18px',
                          position: 'absolute',
                          top: 5,
                          left: '-15px',
                        },
                        my: '10px',
                        padding: '8px 12px',
                        background: isActiveSubItem(item.path) ? '#FF5733' : 'transparent',
                        color: isActiveSubItem(item.path) ? '#FFFFFF' : '#86909F',
                        borderRadius: '8px',
                        height: '40px',
                        '&:hover': {
                          background: '#FF5733',
                          color: '#FFFFFF',
                        },
                      }}
                    >
                      <ListItemText primary={item.text} />
                      {item?.count && (
                        <div
                          style={{
                            marginLeft: '15px',
                            backgroundColor: isActiveSubItem(item.path) ? '#FFFFFF' : '#FF5733',
                            color: isActiveSubItem(item.path) ? '#FF5733' : '#FFFFFF',
                            borderRadius: '8px',
                            padding: '0px 8px',
                          }}
                        >
                          {item?.count}
                        </div>
                      )}
                    </ListItem>
                  )
              )}
            </Menu>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SidebarItem;
