import { gql } from '@apollo/client';

const CREATE_PAIRING_ENTRY = gql`
  mutation createPairingEntry($tournament: Int!, $pairing: Int, $tournamentCategory: Int) {
    createPairingEntry(createPairingEntryInput: { tournament: $tournament, pairing: $pairing, tournamentCategory: $tournamentCategory }) {
      id
    }
  }
`;

export default CREATE_PAIRING_ENTRY;
