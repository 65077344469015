import { gql } from '@apollo/client';

const TOURNAMENT_PREFERENCES = gql`
  query league($id: ID!) {
    league(id: $id) {
      id
      title

      id
      enableLicense
      sports {
        id
        title
        resourceTitle
        enableCourts
        enableStages
        displayDraws
        displayGroups
        displayLiveStandings
        displayOrderPlay
        displayTournamentResults
        displayListResults
        displayPendingResults
        isEnablePairings
        isEnablePairingStandings
        isEnableRounds
        isDisplaySeeds
        isDisplaySegments
      }
    }
  }
`;

export default TOURNAMENT_PREFERENCES;
