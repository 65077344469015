import { gql } from '@apollo/client';

const REMOVE_PAIRING = gql`
  mutation removePairing($id: Int!) {
    removePairing(id: $id) {
      title
    }
  }
`;

export default REMOVE_PAIRING;
