import { gql } from 'src/__generated__/gql';

const PURCHASED_LICENSES = gql(`
  query purchasedLicenses($page: Int, $limit: Int, $filter: ListPurchasedLicensesInput, $getUsers: Boolean, $orderBy: String) {
    purchasedLicenses(page: $page, limit: $limit, filter: $filter, getUsers: $getUsers, orderBy: $orderBy) {
      id
      license {
        id
        fee
        title
      }
      user {
        id
        name
        surname
        email
        phone
        nation
      }
    }
  }
`);
export default PURCHASED_LICENSES;
