import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Grid, Typography, Divider } from '@material-ui/core';
import React, { memo } from 'react';
import { PrimaryButton } from 'src/components/shared/Buttons';

interface TopbarWrapperProps {
  image?: string;
  description?: string;
  title?: string;
  linkOne?: string;
  btnOne?: string;
  linkTwo?: string;
  btnTwo?: string;
  submitLink?: boolean;
  submitBtn?: string;
  onClickBtn?: any;
  submitBtnStartIcon?: any;
  submitBtn2?: string;
  onClickBtn2?: any;
  submitBtn2StartIcon?: any;
}

const TopbarWrapper = (props: TopbarWrapperProps) => {
  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item>
              <img className="tournament_settings_img" src={props.image || '/images/placeholder.svg'} />
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="body1" sx={{ fontWeight: 500, fontFamily: 'Montserrat' }}>
                {props.description}
              </Typography>
              <Typography color="textPrimary" variant="h3" sx={{ fontWeight: 600 }}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            '@media (max-width: 1200px)': {
              paddingTop: '10px !important',
            },
          }}
        >
          <Box>
            <Button
              component={RouterLink}
              sx={{
                '@media (max-width: 1200px)': {
                  mt: 1,
                },
                mr: 1,
                padding: '5px 20px',
                height: '36px',
                backgroundColor: '#F6F6F6',
                color: '#1B386199',
                textTransform: 'uppercase',
              }}
              to={props.linkOne}
            >
              {props.btnOne}
            </Button>
            {props.btnTwo && (
              <Button
                component={RouterLink}
                sx={{
                  '@media (max-width: 1200px)': {
                    mt: 1,
                  },
                  mr: 1,
                  padding: '5px 20px',
                  height: '36px',
                  backgroundColor: '#F6F6F6',
                  color: '#1B386199',
                  textTransform: 'uppercase',
                }}
                to={props.linkTwo}
              >
                {props.btnTwo}
              </Button>
            )}
            {props.submitBtn && (
              <PrimaryButton
                type="submit"
                disabled={props.submitLink}
                variant="contained"
                onClick={props.onClickBtn}
                sx={{
                  textTransform: 'uppercase',
                  minHeight: '36px !important',
                  '@media (max-width: 1200px)': {
                    mt: 1,
                  },
                }}
                startIcon={props.submitBtnStartIcon}
              >
                {props.submitBtn}
              </PrimaryButton>
            )}
            {props.submitBtn2 && (
              <PrimaryButton
                type="submit"
                disabled={props.submitLink}
                variant="contained"
                onClick={props.onClickBtn2}
                sx={{
                  textTransform: 'uppercase',
                  minHeight: '36px !important',
                  '@media (max-width: 1200px)': {
                    mt: 1,
                  },
                  ml: 1,
                }}
                startIcon={props.submitBtn2StartIcon}
              >
                {props.submitBtn2}
              </PrimaryButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          mt: 2,
          '@media (max-width: 768px)': {
            mt: 3,
          },
        }}
      />
    </React.Fragment>
  );
};

export default memo(TopbarWrapper);
