import { ApolloClient, ApolloLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

import Cookies from 'js-cookie';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const customDataIdFromObject = function (object, variables) {
  const queryName = 'allGroups';
  console.log('queryName', queryName);
  switch (object.__typename) {
    case 'Group':
      return `Group:${object.id}:${queryName}`;
    case 'GroupMatch':
      return `GroupMatch:${object.id}:${queryName}`;
    case 'GroupEntry':
      return `GroupEntry:${object.id}:${queryName}`;
    default:
      return object.id || object.__typename; // Fallback to default handling
  }
};

const cache = new InMemoryCache({
  // dataIdFromObject: customDataIdFromObject,
  typePolicies: {
    Query: {
      fields: {
        //  * NEED TO DEFINE WHAT TYPE OF DATA WILL BE MERGED
        leagues: {
          merge: (existing: any, incoming: any) => incoming,
        },
        categories: {
          merge: (existing: any, incoming: any) => incoming,
        },
        tournamentCategories: {
          merge: (existing: any, incoming: any) => incoming,
        },
        federations: {
          merge: (existing: any, incoming: any) => incoming,
        },
        tournamentRegistrations: {
          merge: (existing: any, incoming: any) => incoming,
        },
        tournamentTeamMembers: {
          merge: (existing: any, incoming: any) => incoming,
        },
        categorySettings: {
          merge: (existing: any, incoming: any) => incoming,
        },
        registrationCount: {
          merge: (existing: any, incoming: any) => incoming,
        },
        league: {
          merge: (existing: any, incoming: any) => existing,
        },
        // allGroupMatches: {
        //   merge: (existing: any, incoming: any) => incoming,
        // },
        // allGroupsStandings: {
        //   merge: (existing: any, incoming: any) => incoming,
        // },
        // allGroups: {
        //   merge: (existing: any, incoming: any) => incoming,
        // },
        tournamentAllMatches: {
          merge: (existing: any, incoming: any) => incoming,
        },
        // tournament: {
        //   merge: (existing: any, incoming: any) => existing,
        // },
      },
    },
  },
});

const URL = process.env.REACT_APP_BACKEND_URL;

// const URL = 'https://api.tournated.com/graphql';

// const URL = 'https://staging-api.tournated.com/graphql';

// const URL = 'https://tornated-dev-094f13c08ec4.herokuapp.com/graphql/graphql';

// const URL = 'http://localhost:3000/graphql';

const authLink = setContext((_, { headers }) => {
  // * GET THE AUTHENTICATION TOKEN FROM LOCAL STORAGE IF IT EXISTS
  const token = Cookies.get('token');
  // * RETURN THE HEADERS TO THE CONTEXT SO HTTP-LINK CAN READ THEM
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'apollo-require-preflight': true,
    },
  };
});

const httpLink = createUploadLink({
  uri: URL,
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink as unknown as ApolloLink]),
  cache,
});

export default client;
