import { gql } from '@apollo/client';

const DELETE_DRAW_MATCH = gql`
  mutation removeDrawMatchScore($drawId: Int!, $id: Int!, $winnerId: Int) {
    removeDrawMatchScore(drawId: $drawId, id: $id, winnerId: $winnerId) {
      id
    }
  }
`;

export default DELETE_DRAW_MATCH;
