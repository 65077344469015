import { Dispatch, FC, SetStateAction, useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Scrollbar from "src/components/Scrollbar";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import useAuth from "src/hooks/useAuth";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { UPDATE_USER_PASS } from "src/graphql/mutations";
import { ReactSVG } from "react-svg";

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: "relative",
  },
  passwordToggleBtn: {
    position: "absolute",
    top: "43px",
    right: "18px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    border: "none",
    background: "transparent",
  },
}));


interface ModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  credentials?: any;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}
const ResetPassModal: FC<ModalProps> = ({
  openModal,
  setOpenModal,
  setLoading,
  credentials
}) => {

  const handleClose = () => {
    setOpenModal(false);
  };
  
  const auth = useAuth();
  const yupSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is Required Field"),
    pass: Yup.string().required("Password is Required Field"),
    confirmPass: Yup.string().required("Confirm Password is Required Field").oneOf([Yup.ref('pass'), null], 'Passwords must match')
  });
    
  const [resetPass, { data, loading, error }] = useMutation(UPDATE_USER_PASS) as any;

  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: "512px",
            borderRadius: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            zIndex: 10,
            height: "100%",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              email: credentials?.email,
              pass: credentials?.password,
              confirmPass: credentials?.password,
            }}
            validationSchema={yupSchema}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              try {
                const variables = {
                  email: values.email || "",
                  password: values.pass || "",
                };

                await resetPass({
                  variables,
                });

                handleClose();
                resetForm();
                toast.success(`Reset Password Successfully`);
              } catch (err) {
                toast.error(err?.message);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            }): JSX.Element => (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Paper
                  elevation={12}
                  sx={{
                    width: "100%",
                    maxHeight: "90vh",
                    background: "#FFF",
                    p: 0.5,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "7px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#aaa",
                      borderRadius: "6px",
                      pl: 3,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#a2a2a2",
                    },
                  }}
                >
                  <Scrollbar>
                    <Box
                      sx={{
                        padding: "20px 20px 0px 20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography color="textPrimary" variant="h2">
                        Reset Password
                      </Typography>
                      <CloseIcon
                        sx={{ color: "black", cursor: "pointer" }}
                        onClick={() => handleClose()}
                      />
                    </Box>
                    <Box sx={{ padding: "20px 20px 0px 20px" }}>
                      <Divider />
                    </Box>
                    <Box sx={{ padding: "20px 20px 20px 20px" }}>
                      <TextField
                        autoFocus
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email ? errors.email.toString() : ''}
                          label="Email"
                          margin="normal"
                          name="email"
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                        <Box className={classes.passwordContainer}>
                        <TextField
                          error={Boolean(touched.pass && errors.pass)}
                          fullWidth
                          helperText={touched.pass && errors.pass ? errors.pass.toString() : null}
                          label="Password"
                          margin="normal"
                          name="pass"
                          onChange={handleChange}
                          type={isPasswordVisible ? "text" : "password"}
                          value={values.pass}
                          variant="outlined"
                          />
                          <Box
                            className={classes.passwordToggleBtn}
                            onClick={togglePasswordVisibility}
                          >
                          {isPasswordVisible ? (
                            <ReactSVG src="/images/eye-close.svg" />
                          ) : (
                            <ReactSVG src="/images/eye-open.svg" />
                          )}
                        </Box>
                      </Box>
                      <Box className={classes.passwordContainer}>
                          <TextField
                              error={Boolean(touched.confirmPass && errors.confirmPass)}
                              fullWidth
                              helperText={touched.confirmPass && errors.confirmPass ? errors.confirmPass.toString() : ''}
                              label="Password"
                              margin="normal"
                              name="confirmPass"
                              onChange={handleChange}
                              type={isPasswordVisible ? "text" : "password"}
                              value={values.confirmPass}
                              variant="outlined"
                            />
                            <Box
                              className={classes.passwordToggleBtn}
                              onClick={togglePasswordVisibility}
                            >
                            {isPasswordVisible ? (
                              <ReactSVG src="/images/eye-close.svg" />
                            ) : (
                              <ReactSVG src="/images/eye-open.svg" />
                            )}
                          </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: "0 20px 20px",
                        marginBottom: "5px",
                        display: "flex",
                        gap: "10px",
                        "@media (max-width: 560px)": {
                          flexWrap: "wrap",
                        },
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          display: "inline-flex",
                          height: "50px",
                          padding: "10px 24px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          // background: "#29ABE2",
                          color: "#FFF",
                          textTransform: "uppercase",
                        }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <CircularProgress
                            size={18}
                            color="inherit"
                            sx={{ mr: 1 }}
                          />
                        )}
                        Reset Password
                      </Button>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default ResetPassModal;
