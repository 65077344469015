import { gql } from '@apollo/client';

const UPDATE_CATEGORY_SETTINGS = gql`
  mutation updateCategorySetting(
    $categorySettingId: Int!
    $color: String
    $fee: Int
    $onsiteFee: Int
    $isVATAmount: Boolean
    $vatAmount: Float
    $registrationLimit: Int
    $rounds: Int
    $time: Int
    $timePerMove: Int
    $timeAfterMove: Int
    $extraTime: Int
    $pairing: String
    $method: String!
    $onlinePayments: String!
    $status: String!
    $service: Int!
    $currency: String!
    $mde: Int!
    $mdwc: Int!
    $qe: Int!
    $qwc: Int!
    $mds: Int!
    $qs: Int!
    $consolation: Boolean!
    $tournamentCategory: Int!
  ) {
    updateCategorySetting(
      updateCategorySettingInput: {
        categorySettingId: $categorySettingId
        registrationLimit: $registrationLimit
        rounds: $rounds
        time: $time
        timePerMove: $timePerMove
        timeAfterMove: $timeAfterMove
        extraTime: $extraTime
        pairing: $pairing
        color: $color
        fee: $fee
        onsiteFee: $onsiteFee
        isVATAmount: $isVATAmount
        vatAmount: $vatAmount
        method: $method
        onlinePayments: $onlinePayments
        status: $status
        service: $service
        currency: $currency
        mde: $mde
        mdwc: $mdwc
        qe: $qe
        qwc: $qwc
        mds: $mds
        qs: $qs
        consolation: $consolation
        tournamentCategory: $tournamentCategory
      }
    ) {
      id
      fee
      method
      onlinePayments
      status
      service
      currency
    }
  }
`;

export default UPDATE_CATEGORY_SETTINGS;
