import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import type { Direction, Theme, ThemeOptions } from '@material-ui/core';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';

interface CustomThemeOptions extends ThemeOptions {
  custom?: {
    borderColor?: string;
    iconColor?: string;
  };
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    font15: true;
    heading17: true;
    heading18: true;
    font21: true;
    font22: true;
    font16: true;
    font12: true;
    tableFont: true;
    tableHeadFont: true;
  }
}

const customTypography: { [key: string]: TypographyStyleOptions } = {
  font15: {
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  heading17: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#0A2540',
    '@media (max-width:600px)': {
      fontSize: '18px',
    },
  },
  heading18: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#0A2540',
  },
  font21: {
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
  },
  font22: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
  },
  font16: {
    fontWeight: 500,
    fontSize: '10px',
    fontFamily: 'Inter, sans-serif',
  },
  font12: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '14.4px',
    color: '#86909F',
    textTransform: 'uppercase',
  },
  tableFont: {
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '14.4px',
    textDecoration: 'none',
    color: '#0A2540',
    '&:hover': { color: (theme) => theme.palette.primary.main },
  },
  tableHeadFont: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '14.52px',
    color: '#86909F',
    whiteSpace: 'pre',
    textTransform: 'uppercase',
  },
};

const baseOptions: CustomThemeOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          height: '44px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
          backgroundColor: '#FAFAFA',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  typography: {
    ...customTypography,
    button: {
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '36px',
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 700,
      fontSize: '21px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '17px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '16px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '28px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '20px',
      fontFamily: 'Inter, sans-serif',
    },
    overline: {
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },

    body2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '13px',
    },
  },
  custom: {
    borderColor: '#EDF1F6',
    iconColor: '#425466',
  },
};

const themesOptions: Record<string, CustomThemeOptions> = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            // background: '#FBFBFB',
            border: '1px solid ##EFEFF0',
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
            height: 'auto !important',
          },
        },
      },
    },
    palette: {
      action: {
        active: '#FF5733',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#FF5733',
      },
      secondary: {
        contrastText: '##FAFAFA',
        main: '#FF5733',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#193660',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36',
      },
      divider: '#D3D3D3',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},

      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 8,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  // if (config.responsiveFontSizes) {
  //   theme = responsiveFontSizes(theme);
  // }

  return theme;
};
