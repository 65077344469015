import { gql } from "@apollo/client";

const HIDE_UNHIDE_ALL_DRAWS = gql`
  mutation hideUnhideAllDraws($tournament: Int!, $hide: Boolean!) {
    hideUnhideAllDraws(
      tournament: $tournament
      hide: $hide
    ) {
      id
    }
  }
`;

export default HIDE_UNHIDE_ALL_DRAWS;