import { gql } from '@apollo/client';

const COPY_TOURNAMENT_RANKINGS = gql`
  mutation copyTournamentRanking($tournament: Int!, $rankings: [JSONObject!], $tournamentCategory: Int!, $league: Int!) # $rankingPointId: Int!
  {
    copyTournamentRanking(createTournamentRankingInput: { tournament: $tournament, rankings: $rankings, tournamentCategory: $tournamentCategory, league: $league }) # rankingPointId: $rankingPointId
    {
      id
    }
  }
`;

export default COPY_TOURNAMENT_RANKINGS;
