import { gql } from '@apollo/client';

const ADD_MATCH_SCORE = gql`
  mutation createMatchScore(
    $group: Int!
    $match: Int!
    $entry1partner: Int
    $entry2partner: Int
    $entry1: Int!
    $entry2: Int!
    $score: String
    $courts: Int
    $date: DateTime
    $time: String
    # $status: String
    $matchStatus: String
    $winner: Int
    $winnerPartner: Int
    $scoreType: String
    $isMatchInProgress: Boolean
  ) {
    createMatchScore(
      createMatchScoreInput: {
        group: $group
        match: $match
        entry1: $entry1
        entry2: $entry2
        entry1partner: $entry1partner
        entry2partner: $entry2partner
        score: $score
        courts: $courts
        date: $date
        time: $time
        # status: $status
        matchStatus: $matchStatus
        winner: $winner
        winnerPartner: $winnerPartner
        scoreType: $scoreType
        isMatchInProgress: $isMatchInProgress
      }
    ) {
      round
      score
      roundFormat
      entry {
        id
        place
      }
    }
  }
`;

export default ADD_MATCH_SCORE;
