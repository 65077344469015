import { gql } from '@apollo/client';

const PAIRINGS = gql`
  query pairings($filter: ListPairingInput) {
    pairings(filter: $filter) {
      id
      title
      isCompleted
      #   tournament {
      #     id
      #     title
      #   }
      pairingEntries {
        id
        score
        entry1 {
          id
          users {
            fideTitle
            rank
            blitzElo
            standardElo
            rapidElo
            user {
              id
              email
              name
              surname
              nation
              avatar
            }
          }
        }
        entry2 {
          id
          users {
            fideTitle
            rank
            blitzElo
            standardElo
            rapidElo
            user {
              id
              email
              name
              surname
              nation
              avatar
            }
          }
        }
      }
    }
  }
`;

export default PAIRINGS;
