import { gql } from '@apollo/client';

const UPDATE_USER = gql`
  mutation updateUser(
    $id: Float!
    $name: String
    $surname: String
    $email: String
    $phone: String
    $nation: String
    $gender: String
    $city: String
    $street: String
    $user_club_text: String
    $role: String
    $dob: String
    $duprId: String
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        nation: $nation
        gender: $gender
        city: $city
        street: $street
        user_club_text: $user_club_text
        role: $role
        dob: $dob
        duprId: $duprId
      }
    ) {
      email
      name
      surname
      dob
      email
      nation
      phone
      role
      user_club_text
      street
      city
      gender
      id
    }
  }
`;

export default UPDATE_USER;
