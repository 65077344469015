import { gql } from '@apollo/client';

const UPDATE_USER_RANK = gql`
  mutation updateUserRank($id: Float!, $rank: Int!) {
    updateUser(updateUserInput: { id: $id, rank: $rank }) {
      rank
    }
  }
`;

export default UPDATE_USER_RANK;
