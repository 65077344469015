import { gql } from '@apollo/client';

const UPDATE_GROUP_MATCH = gql`
  mutation updateGroupMatch($id: Int!, $date: DateTime, $time: String, $status: String, $matchStatus: String, $winner: Int, $score: Int, $court: Int, $scoreType: String) {
    updateGroupMatch(updateGroupMatchInput: { id: $id, date: $date, time: $time, status: $status, matchStatus: $matchStatus, winner: $winner, score: $score, court: $court, scoreType: $scoreType }) {
      id
      date
      time
      status
      matchStatus
      winner {
        id
      }
      score {
        round
        score
        roundFormat
        entry {
          id
        }
      }
      court {
        id
        name
      }
      scoreType
    }
  }
`;

export default UPDATE_GROUP_MATCH;
