import { gql } from "@apollo/client";

const TOURNAMENT_CATEGORIES = gql`
  query tournamentCategories {
    tournamentCategories(page: 1, limit: 10, filter: {}) {
      name
      type
      gender
      status
      id
    }
  }
`;

export default TOURNAMENT_CATEGORIES;
