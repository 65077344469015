import { Box, Typography } from '@material-ui/core';
import { Fragment, useState } from 'react';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import GetFlag from 'src/components/shared/GetFlag';
import { useResourceTitle } from 'src/hooks/useResourceTitle';
import { getFideTitle } from 'src/utils/helper-functions';
import UserDetailModal from './UserDetailModal';

const UserName = ({ user, fideTitle }) => {
  const [openModal, setOpenModal] = useState(false);
  const resourceTitle = useResourceTitle();

  return (
    <Fragment>
      <Typography
        variant="body1"
        sx={{
          width: 'max-content',
          color: '#193660',
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: '#29ABE2' },
              maxWidth: '200px',
            }}
          >
            <GetFlag
              country={user?.citizenship ? user?.citizenship : user?.nation}
              style={{
                width: '1.2em',
                height: '1.2em',
                marginRight: '10px',
              }}
            />
            <CustomAvatar src={user?.avatar} size={26} seed={user?.name + ' ' + user?.surname} />

            {/* <Avatar
              sx={{
                width: '30px',
                height: '30px',
                mx: 1,
              }}
              src={user?.avatar}
            /> */}
            <Typography
              variant="tableFont"
              sx={{
                '&:hover': { color: (theme) => theme.palette.primary.main },
                ml: 1,
                textTransform: 'uppercase',
              }}
            >
              {user?.name ? user?.name : 'Not Added'} {user?.surname ? user?.surname : ''}{' '}
            </Typography>

            {resourceTitle === 'Round' && (
              <Box
                sx={{
                  padding: '3px 5px',
                  background: getFideTitle(fideTitle)?.color,
                  color: '#FFFF',
                  borderRadius: '5px',
                  marginRight: '10px',
                  width: 'max-content',
                  marginLeft: '10px',
                }}
              >
                {getFideTitle(fideTitle)?.abbreviation}
              </Box>
            )}
          </Box>
        </Box>
      </Typography>
      <UserDetailModal user={user} openModal={openModal} setModal={setOpenModal} />
    </Fragment>
  );
};

export default UserName;
