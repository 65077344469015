import { gql } from "@apollo/client";

const ADD_MANUAL_GROUP = gql`
  mutation createEmptyGroup(
    $tournament: Int!
    $tournamentCategory: Int!
    $type: String!
    $comment: String!
    $displayPlayOrder: Boolean!
    $displayMatches: Boolean!
    $participantsAddScore: Boolean!
    $totalGroups: Int!
    $naming: String!
    $name: String
    $groupType: String
    $segment: String!
  ) {
    createEmptyGroup(
      createGroupInput: {
        tournament: $tournament
        tournamentCategory: $tournamentCategory
        type: $type
        comment: $comment
        displayPlayOrder: $displayPlayOrder
        displayMatches: $displayMatches
        participantsAddScore: $participantsAddScore
        totalGroups: $totalGroups
        naming: $naming
        name: $name
        groupType: $groupType
        segment: $segment
      }
    ) {
      id
      name
    }
  }
`;

export default ADD_MANUAL_GROUP;
