import { gql } from '@apollo/client';

const CHANGE_MULTIPLE_COURTS_SETTINGS = gql`
  mutation updateMultipleCourts($courtIds: [Int!]!, $updateCourtInput: UpdateCourtInput!) {
    updateMultipleCourts(courtIds: $courtIds, updateCourtInput: $updateCourtInput) {
      id
    }
  }
`;

export default CHANGE_MULTIPLE_COURTS_SETTINGS;
